import React, { useContext, useEffect } from 'react';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import useLocalStorage from 'use-local-storage';
import { Link } from 'react-router-dom'
import ClientsAcrossTheGlobe from '../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe';


function ProductPricingList() {
    const { apiproductdetaildata } = useContext(ApiDataContext);

    const [theme, setTheme] = useLocalStorage('theme', 'light');

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        document.title = "Price List";
        window.scrollTo(0, 0)
    }, [])

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    }

    function getLogoForProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return theme === 'light' ? require('../../assets/images/icons/AMS-w.png') : require('../../assets/images/icons/AMS-b.png');
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../assets/images/icons/LMP-w.png') : require('../../assets/images/icons/LMP-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../assets/images/icons/audit-w.png') : require('../../assets/images/icons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../assets/images/icons/CMS-w.png') : require('../../assets/images/icons/CMS-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../assets/images/icons/spe-Intranet-w.png') : require('../../assets/images/icons/spe-Intranet-b.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../assets/images/icons/HD-w.png') : require('../../assets/images/icons/HD-b.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../assets/images/icons/leave-ms-w.png') : require('../../assets/images/icons/leave-ms-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../assets/images/icons/IMS-w.png') : require('../../assets/images/icons/IMS-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../assets/images/icons/AS-w.png') : require('../../assets/images/icons/AS-b.png');
            default:
                return null;
        }
    }
    function getViewProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005193?exp=ubp8';
            case 'Learning Management Portal':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005182?exp=kyyw';
            case 'Audit Inspection':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005564?exp=ubp8';
            case 'Construction Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005398?exp=kyyw';
            case 'SPE Intranet':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005709';
            case 'HelpDesk System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005785';
            case 'Leave Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200005775';
            case 'Inventory Management System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200006100';
            case 'Approval System':
                return 'https://appsource.microsoft.com/en-us/product/office/WA200006514?tab=Overview';

            default:
                return null;
        }
    }

    const getdetailProduct = (productName) => {
        switch (productName) {
            case 'Asset Management System':
                return '/asset-management-system';
            case 'Learning Management Portal':
                return '/learning-management-system';
            case 'Audit Inspection':
                return '/audit-inspection';
            case 'Construction Management System':
                return '/construction-management-system';
            case 'SPE Intranet':
                return '/spe-intranet';
            case 'HelpDesk System':
                return '/helpdesk-system';
            case 'Leave Management System':
                return '/leave-management-system';
            case 'Inventory Management System':
                return '/inventory-management-system';
            case 'Approval System':
                return '/approval-system';
            default:
                return null;
        }
    }

    return (

        <div>
            <ReactHelmetTag link="https://www.sharepointempower.com/product-pricing-list" />
            <Navbar />
            <div className="fixed-top-container">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title">
                                    <h1 className="h1-tag-lm first-h1-anim h1-heading">Product Pricing List</h1>
                                </div>
                            </div>
                            <div className="col col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title quotationbtn d-flex">
                                    <Link to="/faqs" className='mr-2 pricingfaq'>
                                        <i className="fa fa-circle-question mr-1" />
                                        <span className='m-0 '>FAQs</span>
                                    </Link>
                                    <Link to="/contact-us" className='pricingquotation'>
                                        <i className="fa fa-sticky-note-o mr-1" />
                                        <span className='m-0'>Get Quotation</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="before-row-margin-lm"></div>

                        <table className="pricing-list-table">
                            <thead>
                                <tr>
                                    <th className='table-th' scope="col">Product</th>
                                    <th className='table-th' scope="col">Description</th>
                                    <th className="pricing-plan-content table-th" scope="col">Plan</th>
                                    <th className='table-th btn-width pricing-plan-content' scope="col">Get it now</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*  product.displayOrder === 0  take because we need to add "company specific package" data dynamicly in same api so we select displayOrder 0.*/}
                                {apiproductdetaildata.map((product, index) => {
                                    let updatedTitle = product?.title

                                    if (product.title?.includes('Management') && product.title?.includes('System')) {
                                        updatedTitle = product.title.replace('System', '').trim();
                                    }

                                    return product.displayOrder === 0 ? null : (
                                        <tr key={index}>
                                            <td className="pricing-product-title-col">
                                                <img src={getLogoForProduct(product.title)} className='price-product-icon' ></img>
                                                <a
                                                    className="pricing-product-title"
                                                    href={getdetailProduct(product?.title)}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    tabIndex="-1"
                                                    aria-disabled="true"
                                                >
                                                    {updatedTitle}
                                                </a>
                                                <img src={theme === 'light' ? require('../../assets/images/Microsoft_Badge_white.png') : require('../../assets/images/Microsoft_Badge_black.png')} alt="" className='price-badge-icon'/>
                                            </td>
                                            <td className="pricing-p-col">
                                                <p className="pricing-p" >{product?.description?.slice(0, 105)}
                                                    <Link
                                                        to={getdetailProduct(product?.title)}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        tabIndex="-1"
                                                        aria-disabled="true"
                                                        className='text-xs ps-3'
                                                    >
                                                        ...  Read More
                                                    </Link>
                                                </p>
                                            </td>
                                            <td className="pricing-plan-content" >
                                                <p className="product-price">{product.plan}</p>
                                                <p className="pricing-p">{product?.planDescription}</p>
                                            </td>
                                            <td className="pricing-plan-content">
                                                <a
                                                    href={getViewProduct(product.title)}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    tabIndex="-1"
                                                    aria-disabled="true"
                                                >
                                                    <button className="pricing-card-button">
                                                        Explore
                                                    </button>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <br />
                        {apiproductdetaildata.map((product, index) => (
                            product.displayOrder === 0 && (
                                <div key={index}>
                                    <p className="p-center-content-lm">
                                        <div className="light-mode-title">
                                            <h6 className="h5-title-lm text-align-center h5-title-anim">
                                                {product.title}
                                            </h6>
                                        </div>
                                    </p>
                                    <p className="p-center-content-lm">{product.description}</p>
                                    <p className="p-center-content-lm">{product.productDescription}</p>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </section>
            {/* <CollaborativePartners /> */}
            <ClientsAcrossTheGlobe />
            <Footer />

        </div >

    );
}

export default ProductPricingList;
