import React, { useEffect, useState } from 'react'
import PageHeadingContent from '../../CommonComponents/PageHeadingContent/PageHeadingContent'
import InnerPageBanner from '../../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import ContentCards from '../../CommonComponents/ContentCards/ContentCards'
import CollaborativePartners from '../../CommonComponents/CollaborativePartners/CollaborativePartners'
import SmallFooterBanner from '../../CommonComponents/BackgroundBanners/SmallFooterBanner/SmallFooterBanner'
import { MaintenanceAndSupportCardContentData, MaintenanceAndSupportSmallCardData } from './MaintenanceAndSupportData'
import SmallContentCard from '../../CommonComponents/ContentCards/SmallContentCard'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'

const MaintenanceAndSupport = () => {

  const [maintenanceandsupportcarddata, setMaintenanceAndSupportCardContentData] = useState([])
  const [maintenanceandsupportsmallcarddata, setMaintenanceAndSupportSmallCardData] = useState([])

  useEffect(() => {
    setMaintenanceAndSupportCardContentData(MaintenanceAndSupportCardContentData)
    setMaintenanceAndSupportSmallCardData(MaintenanceAndSupportSmallCardData)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/maintenance-and-support"
        metaKeywords="Maintenance and Support services, Maintenance service, Support service"
      />

      <Navbar />

      <PageHeadingContent heading="Maintenance & Support" subtitle="Protect your software investment with our reliable maintenance and support solutions"
        content="At SharepointEmpower, we are committed to delivering the highest levels of customer satisfaction. We work closely with our clients to understand their needs and requirements, and we strive to exceed their expectations with every project. Our goal is to help businesses achieve their objectives and drive growth and success by providing reliable and cost-effective software maintenance and support services."
        contentTwo="We offer software maintenance and support services to help businesses keep their software up-to-date and running smoothly. Our team of experts can help with everything from bug fixes to feature enhancements, and we offer ongoing support to ensure that our clients' software remains reliable and secure."
      />

      <InnerPageBanner BannerImgClass="maintenance-and-support-1-img" BannerContent="Our maintenance and support services include everything from regular updates and bug fixes to security patches, performance tuning, and user support. We can help you identify and resolve issues before they become major problems, and ensure that your software is always up-to-date and running smoothly." />

      <div className="container-fluid">
        <div className="container">
          <div className="row about-us-inner-card-container kpi-card-row-2-anim" >
            {
              maintenanceandsupportsmallcarddata.map(ele => {
                return (
                  <SmallContentCard cardTitle={ele.cardTitle} cardSubtitle={ele.cardSubtitle} cardContent={ele.cardContent} />
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row service-inner-card-container">
            <h1 className='h4-sontentsubtitle-lm'>Software Maintenance & Support Services</h1>
            {
              maintenanceandsupportcarddata.map(ele => {
                return (
                  <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} />
                )
              })
            }
          </div>
        </div>
      </div>


       {/* <CollaborativePartners /> */}
      

      <Footer />
    </div>
  )
}

export default MaintenanceAndSupport