import React, { useEffect, useContext, useState } from 'react';
import '../Navbar/Navbar.css'
import '../../customJs.js'
import { Link } from 'react-router-dom'
import useLocalStorage from 'use-local-storage';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import TranslateWidget from '../CommonComponents/Translate/Translate';
import { Skeleton } from 'primereact/skeleton';

const Navbar = () => {
    const [loading, setLoading] = useState(true);
    const { apiproductdetaildata } = useContext(ApiDataContext);
    const [toggle, setToggle] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [visible, setVisible] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (apiproductdetaildata && apiproductdetaildata.length > 0) {
            setLoading(false);
        }
    }, [apiproductdetaildata]);

    const toggleTheme = () => {
        var element = document.body;
        element.classList.toggle("dark-mode-body");
    }
    const [theme, setTheme] = useLocalStorage('theme', toggleTheme ? 'dark-mode-body' : 'light');

    useEffect(() => {
        applyTheme(theme)
    }, [theme]);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    }

    const toggleBtn = () => {
        if (theme === 'light') {
            setTheme('dark-mode-body')
        }
        else {
            setTheme('light')
        }
    }

    function getLogoForProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return theme === 'light' ? require('../../assets/images/icons/AMS-w.png') : require('../../assets/images/icons/AMS-b.png');
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../assets/images/icons/LMP-w.png') : require('../../assets/images/icons/LMP-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../assets/images/icons/audit-w.png') : require('../../assets/images/icons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../assets/images/icons/CMS-w.png') : require('../../assets/images/icons/CMS-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../assets/images/icons/spe-Intranet-w.png') : require('../../assets/images/icons/spe-Intranet-b.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../assets/images/icons/HD-w.png') : require('../../assets/images/icons/HD-b.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../assets/images/icons/leave-ms-w.png') : require('../../assets/images/icons/leave-ms-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../assets/images/icons/IMS-w.png') : require('../../assets/images/icons/IMS-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../assets/images/icons/AS-w.png') : require('../../assets/images/icons/AS-b.png');
            default:
                return null;
        }
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.scrollY;
    //         const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 200;

    //         setVisible(isVisible);
    //         setPrevScrollPos(currentScrollPos);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [prevScrollPos]);

    return (
        <>
            <header className={`navbar-main-custom scrolled ${visible ? 'visible' : 'hidden'}`}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid d-flex" >
                        <Link to="/">
                            {theme == "dark-mode-body" ?
                                <img
                                    src={require("../../assets/images/SPE_logo_black_navbar.png")}
                                    className="logo-custom d-inline-block align-text-top" /> :
                                <img
                                    src={require("../../assets/images/SPE_white_logo_HD.png")}
                                    className="logo-custom d-inline-block align-text-top" />
                            }

                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="menu-custom collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">

                                <li className="nav-item">
                                    <div className="dropdown">
                                        {/* <a className="nav-link">Products</a> */}
                                        <Link className="nav-link" to="/all-products">Products</Link>
                                        <div className="dropdown-menu product-dropdown-content overflow-auto pt-0">
                                            <div className='navbarfaq'>
                                                <Link to="/faqs" >
                                                    <i className="fa fa-circle-question mr-1" />
                                                    <span className='m-0'>FAQs</span>
                                                </Link>
                                            </div>
                                            {loading ? (
                                                <ul>
                                                    {[1, 2, 3, 4, 5]?.map((index) => (
                                                        <li key={index}>
                                                            <div className="flex mb-2">
                                                                <Skeleton shape="circle" size="2rem" className='mr-1' />
                                                                <Skeleton width="50%" height="30px" />
                                                            </div>
                                                            <Skeleton width="100%" height="20px" />
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <ul>
                                                    {apiproductdetaildata?.map((product) => {
                                                        let updatedTitle = product.title;

                                                        if (product.title?.includes('Management') && product.title?.includes('System')) {
                                                            updatedTitle = product.title.replace('System', '').trim();
                                                        }

                                                        return product.displayOrder === 0 ? null : (
                                                            <li key={product.title}>
                                                                <Link to={product?.routePath} className='productnamecolor'>
                                                                    <img className="navbar-logo" src={getLogoForProduct(product.title)} alt={updatedTitle} />
                                                                    <span>{updatedTitle}</span>
                                                                    {theme == "dark-mode-body" ?
                                                                        <img className="microsoftbadge" src={require('../../../src/assets/images/Microsoft_Badge_black.png')} alt={updatedTitle} /> :
                                                                        <img className="microsoftbadge" src={require('../../../src/assets/images/Microsoft_Badge_white.png')} alt={updatedTitle} />}
                                                                    <p className='productdescription'>{product.productDescription}</p>
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}

                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/product-pricing-list">Pricing</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <div className="dropdown">
                                        <a className="nav-link">Resources</a>

                                        <div className="dropdown-menu service-dropdown-content">
                                            <ul>
                                                <li><Link to="/contact-us"><a>
                                                    <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                                                    <span>Get Quotation</span>
                                                </a></Link></li>

                                                <li><Link to="/microsoft-based"><a>
                                                    <i className="fa-solid fa-circle-info" aria-hidden="true"></i>
                                                    <span>Help Center</span>
                                                </a></Link></li>
                                            </ul>
                                            <ul>
                                                <li><Link to="/faqs"><a>
                                                    <i className="fa fa-circle-question" />
                                                    <span>FAQs</span>
                                                </a></Link></li>

                                                <li><Link to="https://blog.sharepointempower.com/"><a>
                                                    <i className="fa-solid fa-blog"></i>
                                                    <span>Blog</span>
                                                </a></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> */}

                                <li className="nav-item">
                                    <div className="dropdown">
                                        {/* <a className="nav-link">Services</a> */}

                                        <div className="dropdown-menu service-dropdown-content">
                                            <ul>
                                                <li><Link to="/software-development-services"><a>
                                                    <i className="fa fa-code" aria-hidden="true"></i>
                                                    <span>Software Development Services</span>
                                                </a></Link></li>

                                                <li><Link to="/microsoft-based"><a>
                                                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                                    <span>Microsoft 365 Based</span>
                                                </a></Link></li>
                                            </ul>
                                            <ul>
                                                <li><Link to="/qa-testing"><a>
                                                    <i className="fa fa-wrench" aria-hidden="true"></i>
                                                    <span>QA & Testing</span>
                                                </a></Link></li>

                                                <li><Link to="/ui-ux-design-services"><a>
                                                    <i className="fa-sharp fa-solid fa-wand-magic-sparkles"></i>
                                                    <span>User Interaction/User Experience</span>
                                                </a></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <div className="dropdown">
                                        <a className="nav-link">Company</a>

                                        <div className="dropdown-menu company-dropdown-content sm-tab">
                                            <ul>
                                                <li><Link to="/about-us"><a>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                    <span>About Us</span>
                                                </a></Link></li>
                                                {/* <li><Link to="/our-commitment"><a>
                                                    <i className="fa-solid fa-handshake"></i>
                                                    <span>Our Commitment</span>
                                                </a></Link></li> */}
                                                <li><Link to="/career-at-sharepointempower"><a>
                                                    <i className="fa-solid fa-users-line"></i>
                                                    <span>Career at SharepointEmpower</span>
                                                </a></Link></li>
                                            </ul>
                                            <ul>
                                                <li><Link to="/find-us"><a>
                                                    <i className="fa-sharp fa-solid fa-map-location-dot"></i>
                                                    <span>Find Us</span>
                                                </a></Link></li>

                                                <li><Link to="/contact-us"><a>
                                                    <i className="fa-sharp fa-solid fa-address-card"></i>
                                                    <span>Contact Us</span>
                                                </a></Link></li>
                                            </ul>
                                            {/* <ul>
                                                <li><Link to="/job-openings"><a>
                                                    <i className="fa-sharp fa-solid fa-user-plus"></i>
                                                    <span>Job Openings</span>
                                                </a></Link></li>
                                            </ul> */}
                                            <ul>
                                                {/* <li><Link to="/our-team"><a>
                                                    <i className="fa-solid fa-people-group"></i>
                                                    <span>Our Team</span>
                                                </a></Link></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/quotation"
                                    >Get in quote
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us"> <span className='px-1 border-1 border-round-sm border-400'>Get in touch</span></Link>
                                </li>

                                <li className="nav-item ">
                                    <Link className="nav-link" to=""><TranslateWidget /></Link>
                                </li>

                                <li className="nav-item">
                                    {window.innerWidth <= 991 ?
                                        <div className="nav-link nav-link-toggler"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarNav"
                                            aria-controls="navbarNav"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            id='toggleTheme' onClick={() => {
                                                toggleTheme()
                                                toggleBtn()
                                            }}>
                                            {theme == "dark-mode-body" ?
                                                <i className="fa-solid fa-moon toggle-image" aria-hidden="true"></i>
                                                : <i className="fa-solid fa-sun toggle-image" aria-hidden="true"></i>}
                                        </div>
                                        :
                                        <div className="nav-link nav-link-toggler"
                                            id='toggleTheme' onClick={() => {
                                                toggleTheme()
                                                toggleBtn()
                                            }}>
                                            {theme == "dark-mode-body" ?
                                                <i className="fa-solid fa-moon toggle-image" aria-hidden="true"></i>
                                                : <i className="fa-solid fa-sun toggle-image" aria-hidden="true"></i>}
                                        </div>
                                    }
                                </li>

                            </ul>
                        </div>

                    </div>
                </nav>
            </header >
        </>
    )
}

export default Navbar