import React from "react";
import Footer from "../../Footer/Footer";
import ReactHelmetTag from "../../CommonComponents/ReactHelmet/ReactHelmetTag";
import KnowledgeBaseHeading from "../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading";
import Allprojects from "../../CommonComponents/Userguide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function AuditGuide() {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/audit-inspection"
        metaKeywords="Audit inspection guidelines, Audit inspection installation steps, Audit inspection help center, Sharepoint Audit inspection, Audit inspection steps"
      />

      {/* <KnowledgeBaseHeading
        heading="Knowledge Base"
        downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200005564?exp=ubp8"
        title="Download Audit Inspection"
        modalTitle=" Audit Inspection"
      /> */}
      <section>
        <LazyLoadComponent>
          <Allprojects project="Audit Inspection" ></Allprojects>
        </LazyLoadComponent>
      </section>
    </div>
  );
}

export default AuditGuide;
