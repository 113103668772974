import Carousel from "react-multi-carousel";
import {Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};
const images = [
    require("../../assets/images/icons/figma.png"),
    require("../../assets/images/icons/adobeillustrator.png"),
    require("../../assets/images/icons/adobexd.png"),
    require("../../assets/images/icons/photoshop.png"),
    require("../../assets/images/icons/canva.png"),
    require("../../assets/images/icons/webflow.png"),
    require("../../assets/images/icons/sketch.png"),
    require("../../assets/images/icons/invision.png") ,
    require("../../assets/images/icons/notion.png"),
];

const UxCarousel = ({ deviceType }) => {
    return (
        <Carousel
            ssr
            partialVisbile
            deviceType={deviceType}
            itemClass="image-item"
            responsive={responsive}
            autoPlay
        >
            {images.slice(0, 12).map(image => {
                return (
                    <div>
                        <div className="tech-icon-container-lm">
                            <div className="tech-QA-slider-lm d-flex-center-custom">
                                <Image
                                    draggable={true}
                                    src={image}
                                />
                            </div>

                        </div>

                    </div>

                );
            })}
        </Carousel>
    );
};

export default UxCarousel;
