import React, { useContext, useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import useLocalStorage from 'use-local-storage';

const Footer = () => {
    const { apiproductdetaildata } = useContext(ApiDataContext);
    let newDate = new Date().getFullYear();

    const toggleTheme = () => {
        var element = document.body;
        element.classList.toggle("dark-mode-body");
    }
    const [theme, setTheme] = useLocalStorage('theme', toggleTheme ? 'dark-mode-body' : 'light');


    
    useEffect(() => {
        applyTheme(theme)
    }, [theme]);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    }

    return (
        <div className="dark-mode-footer-subtract-bg light-mode-footer-body transparentfooter">

            <div className="container-fluid">
                <div className="container">
                    {/* <footer> */}
                    {/* <!-- row start --> */}
                    <div className="footer-list-container" >
                        <div className="row">

                            <div className="footer-col-4-anim col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="footer-list">

                                    <ul>
                                        <h5>Company</h5>
                                        <li><Link to="/about-us"><a>About us</a></Link></li>
                                        <li><Link to="/our-commitment"><a>Our Commitment</a></Link></li>
                                        <li><Link to="/career-at-sharepointempower"><a>Career at SharepointEmpower</a></Link></li>
                                        <li><Link to="/contact-us"><a>Contact us</a></Link></li>
                                    </ul>

                                </div>
                            </div>

                            <div className='row col col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className="footer-col-1-anim col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 product-footer">
                                    <div className="footer-list">
                                        <ul>
                                            <h5>Products</h5>
                                            {apiproductdetaildata?.slice(0, Math.ceil(apiproductdetaildata.length / 2)).map((e) => {

                                                let updatedTitle = e?.title

                                                if (e.title?.includes('Management') && e.title?.includes('System')) {
                                                    updatedTitle = e.title.replace('System', '').trim();
                                                }

                                                return e.displayOrder === 0 ? null : (
                                                    <li key={e.routePath}><Link to={e?.routePath}><a>{updatedTitle}</a></Link></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-col-2-anim col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 product-item product-footer">
                                    <div className="footer-list">
                                        <ul>
                                            <h5></h5>
                                            {apiproductdetaildata?.slice(Math.ceil(apiproductdetaildata.length / 2)).map((e) => {
                                                let updatedTitle = e?.title

                                                if (e.title?.includes('Management') && e.title?.includes('System')) {
                                                    updatedTitle = e.title.replace('System', '').trim();
                                                }

                                                return e.displayOrder === 0 ? null : (
                                                    <li key={e.routePath}><Link to={e?.routePath}><a>{updatedTitle}</a></Link></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="footer-col-2-anim col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="footer-list">

                                    <ul>
                                        <h5>Services</h5>
                                        <li><Link to="/software-development-services"><a>Software Development Services</a></Link></li>
                                        <li><Link to="/microsoft-based"><a>Microsoft 365 Based</a></Link></li>
                                        <li><Link to="/ui-ux-design-services" ><a>UX/UI Design</a></Link></li>
                                        <li><Link to="/qa-testing" ><a>QA & Testing</a></Link></li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mobile-d-none">
                                <div className="footer-list">

                                    <ul>
                                        <h5>Resources</h5>
                                        <li><a href="https://blog.sharepointempower.com/" target="_blank" rel="noreferrer noopener" >Blog</a></li>
                                        <li><Link to="/faqs" ><a>FAQs</a></Link></li>
                                        <li><Link to="/contact-us" ><a>Get Quotation</a></Link></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- row end --> */}

                    {/* <!-- row start --> */}

                    <div className="footer-logo-box footer-logo-box-anim">
                        <div className="row">
                            <div className="col col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mobile-footer-flex mobile-footer-padding">
                                <Link to="/">
                                    <a>
                                        {theme == "dark-mode-body" ?
                                            <img className="footer-logo-img" src={require("../../assets/images/SPE_logo_black_navbar.png")} alt="SPE_logo_white" /> :
                                            <img className="footer-logo-img" src={require("../../assets/images/SPE_logo_white_navbar.png")} alt="SPE_logo_white" />}
                                    </a>
                                </Link>
                                <img className="indian-flag" src={require("../../assets/images/icons/india.png")} alt="indian-flag" />
                                <div>
                                    <p className='mb-0 ml-2 text-xs mt-1 ' style={{ 'color': 'gray' }}>CIN: U72900GJ2021PTC120956</p>
                                </div>
                            </div>
                            <div className='row col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mobile-footer-padding'>
                                <div className="col col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mobile-footer-padding">
                                    <div className="footer-logo-list">
                                        <span>Follow us</span>
                                        <ul>
                                            <li>
                                                <a href="https://in.linkedin.com/company/sharepointempower"
                                                    target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-linkedin"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/sharepointempower_/?hl=en" target="_blank"
                                                    rel="noreferrer noopener"><i className="fa-brands fa-square-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/@sharepointempower/featured" target="_blank"
                                                    rel="noreferrer noopener"><i className="fa-brands fa-square-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col col-7 col-sm-6 col-md-7 col-lg-7 col-xl-7 col-xxl-7 setimg mobile-footer-padding">
                                    <a href='https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf' target='_blank'>
                                        <img className="footer-MS-img " src={require("../../assets/images/service_inner_page/Microsoft-solution-partner.png")} alt="SPE_logo_white" />
                                    </a>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mobile-footer-padding">
                                <div className="footer-logo-list">
                                    <span>Contact us</span>
                                    <ul>
                                        <li>
                                            <i className="fas fa-envelope-open"></i><a href="mailto:support@sharepointempower.com"> support@sharepointempower.com</a>
                                        </li>
                                        <li className='mobile-d-none'>
                                            <i className="fas fa-user-tie"></i><a href="mailto:career@sharepointempower.com" > career@sharepointempower.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={window.innerWidth > 768 ? 'flex justify-content-between mx-3' : ''}>
                        {/* <!-- row start --> */}

                        <div className="row footer-copyright-anim">
                            <div
                                className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                            >
                                <div className="footer-policy-list d-flex-center-custom">
                                    <ul id="copyright">
                                        <li>© {newDate} All Rights Reserved</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row footer-policy-list-anim">
                            <div
                                className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                            >
                                <div className="footer-policy-list d-flex-center-custom">
                                    <ul>
                                        {/* <li><Link to="/FAQ">FAQ |</Link> </li> */}
                                        <li><Link to="/privacy-policy">Privacy Policy |</Link> </li>
                                        <li><Link to="/legal-terms-of-use">Legal Terms of Use |</Link>  </li>
                                        <li><Link to="/site-map">Site Map</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <!-- row end --> */}

                    </div>
                    {/* <!-- row end --> */}

                    {/* <!-- row start --> */}

                    {/* <!-- row end --> */}
                    {/* </footer> */}
                </div>
            </div >
            {/* </section > */}
        </div >
    )
}

export default Footer