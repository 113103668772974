import React, { useEffect } from 'react'
import Banner from './Banner'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ClientsAcrossTheGlobe from '../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductOverview from '../Product/ProductPage/ProductOverview'
import useLocalStorage from 'use-local-storage'

const HomePage = () => {

  useEffect(() => {
    document.title = "SharepointEmpower Pvt. Ltd.";
    window.scrollTo(0, 10);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1500);
  }, [])

  const [theme, setTheme] = useLocalStorage('theme', 'light');

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar-main-custom');
      const links = document.querySelectorAll('.navbar-main-custom .nav-link');
      const logo = document.querySelector('.logo-custom');

      if (window.scrollY < 200) {
        navbar.classList.remove('scrolled');
        logo.src = require('../../assets/images/SPE_white_logo_HD.png');
        links.forEach(link => link.style.color = 'white');
      } else {
        navbar.classList.add('scrolled');
        if (window.scrollY > 200 && theme == "light") {
          logo.src = require('../../assets/images/SPE_white_logo_HD.png')
        } else {
          logo.src = require('../../assets/images/SPE_logo_black_navbar.png')
        }
        links.forEach(link => link.style.color = 'black');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.transparentfooter');
      if (window.scrollY > 200) {
        navbar.classList.add('footerscrolled');
      } else {
        navbar.classList.remove('footerscrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div id='homepage'>
        <ReactHelmetTag
          link="https://www.sharepointempower.com"
          metaKeywords="SharepointEmpower pvt ltd, Sharepointempower company, SharepointEmpower Rajkot, Rajkot SharepointEmpower Pvt. ltd., Sharepointempower homepage"
        />

        <Navbar />
        <Banner />
        <div className='container mt-4'>
          <div>
            <h3 className=' allfirst-section-margin-top-for-navbar' style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions</h3>
          </div>
          <ProductOverview />
        </div>
        <ClientsAcrossTheGlobe />
        {/* <AboutUsSection /> */}
        <Footer />
      </div>
      {/* <!-- cookie start--> */}
      <div className="modal fade cookie-container-anim cookieModal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog cookie-row" role="document">
          <div className="modal-content relative bg-black border-round">
            <div className="modal-body text-center">
              <div>
                <h5 className='text-xl text-white font-semibold'>Here’s how we use cookies</h5>
              </div>
              <p className='my-4 text-white'>We aim to bring you the best, most personalized experience. Functional, Analytics/Performance, and Advertising/Targeting optional cookies help to make this possible.</p>
              <div className="buttons flex flex-row align-items-center justify-content-center flex-wrap">
                <button href="javascript:;" className="btn btn-cookie-custom-allow border-solid p-2 m-1 w-3" id="cookie-allow" data-dismiss="modal">Allow</button>
                <button href="javascript:;" className="btn btn-cookie-custom-decline border-solid p-2 m-1 w-3" id="cookie-decline" data-dismiss="modal">Decline</button>
              </div>
            </div>
            <div className='cookie-background-image'></div>
          </div>
        </div>
      </div>
      {/* <!-- cookie end--> */}
    </>
  )
}

export default HomePage;
