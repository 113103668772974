import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const PageHeadingContent = (props) => {
    return (
        <div>
            <div className="container-fluid">
                <div className="light-mode-subtract-bg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div id="first-section-margin-top-for-navbar" className="light-mode-title">
                                <h1 className="h1-tag-lm first-h1-anim">{props.heading}</h1>
                            </div>

                            <div className="light-mode-subtitle">
                                <h2 className="h2-subtitle-lm first-h2-anim">
                                    {props.subtitle}
                                </h2>
                            </div>

                            <div className="light-mode-content">
                                <p className="p-content-lm first-p-anim">
                                    {props.content}
                                </p>
                            </div>

                            <div className="light-mode-content">
                                <p className="p-content-lm first-p-anim">
                                    {props.contentTwo}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeadingContent