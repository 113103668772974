export const ServiceInnerContentData = [
    {
        heading: "Software Development Services",
        subtitle: "Empowering your digital transformation with expert software development",
        content: "Software development is an essential part of modern business, enabling companies to automate processes, improve efficiency, and deliver better experiences to their customers. At SharepointEmpower, we offer comprehensive software development services to help businesses build bespoke solutions that meet their unique needs.",
        contentTwo: "Our software development services includes:"
    }
]

export const CardContentData = [
    {
        cardTitle: "Custom Software Development",
        cardContent: "We specialize in custom software development services tailored to meet the unique needs of our clients. Whether you need a new application built from scratch, an existing system optimized.",
        link: "/custom-software-development"
    },
    {
        cardTitle: "Web Application Development",
        cardContent: "We are committed to providing innovative software products that meet the evolving needs of our clients. Click here to learn more about our software product services.",
        link: "/web-application-development-services"
    },
    {
        cardTitle: "Maintenance & Support system",
        cardContent: "We develop a maintenance and support plan that aligns with your business needs and budget. Whether you need ongoing maintenance and support or occasional assistance.",
        link: "/maintenance-and-support"
    }
]