export const WebAppDevCardContentData = [
    {
        cardTitle: "Front-End Development",
        cardContent: "Front-end development is the process of creating the user-facing part of a website or web application. It involves using a combination of programming languages, frameworks, and tools to create a visually appealing and interactive experience for users.",
    },
    {
        cardTitle: "Back-End Development",
        cardContent: "Back-end development involves creating the server-side of a web application or website. This includes the server-side code that communicates with the database and performs data processing, as well as the APIs and web services that expose this functionality to the front-end.",
    },
    {
        cardTitle: "Responsive Design",
        cardContent: "One of the key benefits of using a responsive design service is that it can help you reach a broader audience. By creating web pages that are optimized for different screen sizes and devices, you can ensure that your website is accessible to users on desktops, laptops, tablets, and smartphones."
    }
]