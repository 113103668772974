export const MicrosoftBasedCardContentData = [
    {
        cardTitle: "Asset Management System",
        cardContent: "Asset management system is a platform that helps in finding the location, usage, cost, life cycle, and renewals of services in a couple of clicks and automates your asset’s life cycles.",
        link: "/asset-management-system"
    },
    {
        cardTitle: "Learning Management Portal",
        cardContent: "LMP is the easiest way to onboard and upskill the employees, train customer-facing teams, and enable customers and partners—all from one place.",
        link: "/learning-management-system"
    },
    {
        cardTitle: "Audit Inspection",
        cardContent: "An Audit Inspection generally focuses on the audit process to make sure that auditors have conducted appropriate tests and provided adequate documentation of their conclusions.",
        link: "/audit-inspection"
    },
    {
        cardTitle: "Construction Management System",
        cardContent: "Construction Management Software provides this elevated clarity and puts it in the right hands—those of your project teams.",
        link: "/construction-management-system"
    },
    {
        cardTitle: "SPE Intranet",
        cardContent: "Our SPE Intranet management system also facilitates seamless collaboration among team members. It offers features like Document libraries, Sort & searching functionality.",
        link: "/spe-intranet"
    },
    {
        cardTitle: "Leave Management System",
        cardContent: "Introducing leave management system: a powerful solution designed to revolutionize the way organizations handle employee leaves.",
        link: "/leave-management-system"
    },
    {
        cardTitle: "Helpdesk",
        cardContent: "SharepointEmpower Pvt. Ltd. Helpdesk software application, also known as a helpdesk ticketing system or support ticket system.",
        link: "/helpdesk-system"
    },
    {
        cardTitle: "Inventory Management System",
        cardContent: "Boost your operational efficiency with our Inventory Management System. Effortlessly oversee orders, interactions, and inventory to ensure precision and productivity.",
        link: "/inventory-management-system"
    },
    {
        cardTitle: "Approval System",
        cardContent: "Boost your operational efficiency with our Inventory Management System. Effortlessly oversee orders, interactions, and inventory to ensure precision and productivity.",
        link: "/approval-system"
    }
]

export const QaTestingSmallCardData = [
    {
        cardTitle: <i class="far fa-check-circle"></i>,
        cardSubtitle: "Quality assurance",
        cardContent: "It involves a variety of testing techniques, including functional testing, performance testing, usability testing, security testing, and more. The goal of QA is to detect and address defects early in the development process, before they become more difficult and expensive to fix."
    },
    {
        cardTitle: <i class="fas fa-tasks"></i>,
        cardSubtitle: "Software Testing",
        cardContent: "It involves a wide range of activities, including identifying test scenarios, designing test cases, executing test cases, reporting defects, and verifying fixes. Different types of software testing include functional testing, performance testing, security testing, usability testing."
    }
]

export const UiUxDesignSmallCardData = [
    {
        cardTitle: <i class="fas fa-edit"></i>,
        cardSubtitle: "User Interface",
        cardContent: "To ensure that our UI/UX design meets the user's needs & expectations, they conduct user research - usability testing. They use data-driven insights to improve the UI design & make sure that it provides an excellent user experience also ensures that our UI is responsive & accessible to users with disabilities."
    },
    {
        cardTitle: <i class="fas fa-tasks"></i>,
        cardSubtitle: "User Experience",
        cardContent: "Our UI/UX designer also develops and maintains design wireframes, prototypes, and specifications for our digital products. They use design tools, such as Sketch, Figma, or Adobe Creative Suite, to create visual designs that communicate the product's intended purpose & meet our branding requirements."
    }
]

export const UiUxDesignCardContentData = [
    {
        cardTitle: "User Research",
        cardContent: "We conduct user research to gain insights into the user's behavior, needs, and preferences. This research helps us create digital products that meet the user's needs and expectations.",
    },
    {
        cardTitle: "Visual design",
        cardContent: "We create visually appealing and user-friendly interfaces that reflect the brand's personality and values while providing a great user experience.",
    },
    {
        cardTitle: "Information architecture",
        cardContent: "We develop an information architecture that organizes the content and structure of the digital product, making it easy for users to navigate and find what they need."
    },
    {
        cardTitle: "Wireframing",
        cardContent: "Wireframing helps designers and stakeholders visualize the design's basic structure and layout before investing significant resources in its development. Using wireframing tools, such as Sketch, Figma, or Adobe XD, to create wireframes that accurately reflect the digital product's structure & user flow.",
    },
    {
        cardTitle: "Front-end developmentt",
        cardContent: "We work closely with our front-end development team to ensure that the UI/UX design is technically feasible and can be implemented on the chosen platform.",
    },
    {
        cardTitle: "Prototyping",
        cardContent: "Prototyping is a critical step in the UI/UX design process that involves creating a high-fidelity representation of the digital product's design. A prototype is a clickable and interactive version of the design that helps designers and stakeholders test the user flow, functionality, and overall user experience."
    }
]

export const QaTestingCardContentData = [
    {
        cardTitle: "Functional Testing",
        cardContent: "Verifies that the software performs as expected and meets the functional requirements. Test cases are designed to cover all aspects of the software's functionality and ensure that all features work as intended. Several types, including unit testing, integration testing, system testing, and acceptance testing.",
    },
    {
        cardTitle: "Security Testing",
        cardContent: "We use industry-standard tools and techniques to identify vulnerabilities such as outdated software versions, unsecured ports, and configuration errors. Our team of experts can provide detailed reports outlining vulnerabilities and recommendations for remediation.",
    },
    {
        cardTitle: "Performance Testing",
        cardContent: "Our performance testing services are designed to help our clients ensure that their software applications can handle a high volume of users and transactions without performance issues. Our team of experts can work with our clients to develop customized testing strategies that meet their specific needs."
    },
    {
        cardTitle: "Usability Testing",
        cardContent: "It involves testing the software application's usability to ensure that it is intuitive and easy to use. We use industry-standard tools and techniques to recruit participants, conduct testing, and analyze results. We provide detailed reports outlining interface issues and recommendations for improvement.",
    },
    {
        cardTitle: "UI Testing",
        cardContent: "User interface testing services involve testing the software application's user interface to ensure that it has a visually appealing, consistent, and user-friendly interface. We use industry-standard tools and techniques to test the user interface's visual elements, including fonts, colors, and images.",
    }
]

export const QaTestingFeatureContentData = [
    {
        iconName: "speed_accuracy",
        title: "Speed and Efficiency",
    },
    {
        iconName: "repeat",
        title: "Consistency and Repeatability",
    },
    {
        iconName: "test_coverage",
        title: "Increased Test Coverage",
    },
    {
        iconName: "dollar_icon",
        title: "Cost Savings",
    },
    {
        iconName: "accurate",
        title: "Scalability & Improved Accuracy",
    },
    {
        iconName: "report_icon",
        title: "Early Detection of Defects",
    }
]