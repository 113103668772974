export const AssetManagementSystemfeatureData = [
    {
        iconName: "logging-audits",
        title: "Asset history log",
    },
    {
        iconName: "asset-location",
        title: "Asset allocation to any location & users",
    },
    {
        iconName: "nesdted-assets",
        title: "Dashboards & Reports",
    },
    {
        iconName: "asset-kits",
        title: "Asset project kits/ Nested assets",
    },
    {
        iconName: "employee-wishlist",
        title: "Employee Wishlist",
    },
    {
        iconName: "material-symbol-icon",
        title: "Manage Assets from anywhere",
    },
    {
        iconName: "person-group-icon",
        title: "Role-Based Access Control",
    },
    {
        iconName: "twotone-barcode-icon",
        title: "Utilize Integrated Barcode Scanner",
    },
    {
        iconName: "customizable-fields",
        title: "Customizable Fields",
    },
]

export const LMPfeatureData = [
    {
        iconName: "microsoft_365_certified",
        title: "Microsoft 365 App Compliance Certified",
    },
    {
        iconName: "user_tracking",
        title: "User Management & Tracking",
    },
    {
        iconName: "live_training_support",
        title: "Live training support",
    },
    {
        iconName: "apply_quiz",
        title: "Quiz Assessments & Results",
    },
    {
        iconName: "progress_report",
        title: "Course progress report",
    },
    {
        iconName: "course_certificate",
        title: "Course certificates",
    }, 
    {
        iconName: "microsoft-teams-icon",
        title: "Microsoft Teams Integration",
    },
    {
        iconName: "no_hosting_cost",
        title: "No hosting cost",
    },
    {
        iconName: "highly_customizable",
        title: "Highly customizable learning portal",
    },
]

export const AuditfeatureData = [
    {
        iconName: "customizable-fields",
        title: "Customizable Fields",
    },
    {
        iconName: "risk_icon",
        title: "Reduce Cost & Risk",
    },
    {
        iconName: "productivity_icon",
        title: "Increase Efficiency & Productivity",
    },
    {
        iconName: "grow_icon",
        title: "Drive Growth",
    },
    {
        iconName: "report_icon",
        title: "Standardize Reporting",
    },
    {
        iconName: "maintainance_icon",
        title: "Maintain Compliance",
    },
]

export const CMSfeatureData = [
    {
        iconName: "document_icon",
        title: "Document & Project Management",
    },
    {
        iconName: "report_icon",
        title: "Reporting & Analytics",
    },
    {
        iconName: "mobile-phone-icon",
        title: "Mobile Responsive"
    },
    {
        iconName: "dollar_icon",
        title: "Budgeting & Cost Control",
    },
    {
        iconName: "communicate_icon",
        title: "Communication & Collaboration",
    },
    {
        iconName: "safety_icon",
        title: "Improve Quality & Safety",
    },
]

export const SPEfeatureData = [
    {
        iconName: "document_icon",
        title: "Document & File Management",
    },
    {
        iconName: "communicate_icon",
        title: "Communication & Collaboration Tools",
    },
    {
        iconName: "employee_directory_icon",
        title: "Employee Directory & Profiles"
    },
    {
        iconName: "news_icon",
        title: "News & Announcements",
    },
    {
        iconName: "search_icon",
        title: "Search Functionality",
    },
    {
        iconName: "mobile-phone-icon",
        title: "Mobile Access & Integration",
    },
]

export const LeaveMSfeatureData = [
    {
        iconName: "document_icon",
        title: "Leave request submission",
    },
    {
        iconName: "mobile-phone-icon",
        title: "Mobile Responsive",
    },
    {
        iconName: "report_icon",
        title: "Reporting and analytics "
    },
    {
        iconName: "leave_workflow_icon",
        title: "Approval workflow",
    },
    {
        iconName: "balance_icon",
        title: "Real-time leave balance tracking",
    },
    {
        iconName: "self_service_icon",
        title: "Employee self-service portal",
    },
]

export const HDfeatureData = [
    {
        iconName: "document_icon",
        title: "Create & manage tickets",
    },
    {
        iconName: "turn-around-time",
        title: "Track Estimated Resolution time (TAT)",
    },
    {
        iconName: "integrated-faq",
        title: "FAQs Integration",
    },
    {
        iconName: "productivity_icon",
        title: "Allocate designated users & priorities",
    },
    {
        iconName: "ticket_icon",
        title: "Ticket organization by category",
    },
    {
        iconName: "report_icon",
        title: "Attachment support",
    },
    {
        iconName: "microsoft-teams-icon",
        title: "Microsoft Teams Integration",
    },
    {
        iconName: "global-search",
        title: "Global search",
    }, 
    {
        iconName: "communicate_icon",
        title: "Email notifications "
    },
]

