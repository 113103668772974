export const MaintenanceAndSupportCardContentData = [
    {
        cardTitle: "Performance optimization",
        cardContent: "This includes fine-tuning the software to improve its speed and efficiency. This can be achieved by identifying and removing performance bottlenecks, reducing resource consumption, and improving response times."
    },
    {
        cardTitle: "Security updates",
        cardContent: "This involves identifying and fixing any vulnerabilities in the software to prevent security breaches."
    },
    {
        cardTitle: "Feature updates",
        cardContent: "This involves adding new features and functionality to the software to keep it up-to-date and competitive in the market."
    },
    {
        cardTitle: "Technical support",
        cardContent: "This involves providing assistance to users who encounter problems with the software, either through online resources or through direct communication with support personnel."
    }
]

export const MaintenanceAndSupportSmallCardData = [
    {
        cardTitle: <i class="fas fa-tools"></i>,
        cardSubtitle: "Maintenance",
        cardContent: "It involves the process of identifying, analyzing, and fixing any issues or bugs that may arise. Includes updating the software to address security vulnerabilities, compatibility issues with new hardware or software, and other technical issues that can affect the software's performance."
    },
    {
        cardTitle: <i class="far fa-question-circle"></i>,
        cardSubtitle: "Support",
        cardContent: "Software support services involve providing assistance to end-users who may encounter issues while using the software. This can include providing technical support, troubleshooting, and answering questions about the software's features and functionality."
    }
]