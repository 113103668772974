import React, { useEffect, useState, useContext } from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton'; // Import Skeleton component
import './MegaMenuDemo.css';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';

export default function TemplateDemo() {
    const [loading, setLoading] = useState(true);
    const { apiproductdetaildata } = useContext(ApiDataContext);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (apiproductdetaildata && apiproductdetaildata.length > 0) {
            const dynamicItemsLeft = apiproductdetaildata.slice(0, 4).map(product => ({
                label: product.title,
                image: getLogoForProduct(product.title),
                subtext: product.productDescription,
                path: product.routePath,
                template: itemRenderer
            }));

            const dynamicItemsRight = apiproductdetaildata.slice(4).map(product => ({
                label: product.title,
                image: getLogoForProduct(product.title),
                subtext: product.productDescription,
                path: product.routePath,
                template: itemRenderer
            }));

            const updatedItems = [
                {
                    label: 'Company',
                    root: true,
                    template: itemRenderer,
                    path: '/company',
                    items: [
                        [
                            {
                                items: dynamicItemsLeft
                            }
                        ],
                        [
                            {
                                items: dynamicItemsRight
                            }
                        ]
                    ]
                },
                {
                    label: 'Resources',
                    root: true,
                    template: itemRenderer,
                    path: '/resources'
                },
                {
                    label: 'Contact',
                    root: true,
                    template: itemRenderer,
                    path: '/contact-us'
                }
            ];

            setItems(updatedItems);
            setLoading(false);
        }
    }, [apiproductdetaildata]);

    const getLogoForProduct = (productName) => {
        switch (productName) {
            case 'Learning Management Portal':
                return require('../../assets/images/icons/LMP-w.png');
            case 'Asset Management System':
                return require('../../assets/images/icons/AMS-w.png');
            case 'Audit Inspection':
                return require('../../assets/images/icons/audit-w.png');
            case 'Construction Management System':
                return require('../../assets/images/icons/CMS-w.png');
            case 'SPE Intranet':
                return require('../../assets/images/icons/spe-Intranet-w.png');
            case 'HelpDesk System':
                return require('../../assets/images/icons/HD-w.png');
            case 'Leave Management System':
                return require('../../assets/images/icons/leave-ms-w.png');
            case 'Inventory Management System':
                return require('../../assets/images/icons/IMS-w.png');
            case 'Approval System':
                return require('../../assets/images/icons/AS-w.png');

            default:
                return null;
        }
    };

    const itemRenderer = (item, options) => {
        if (item.root) {
            return (
                <Link to={item.path} className="flex align-items-center cursor-pointer px-3 py-2 overflow-hidden relative font-semibold text-lg uppercase p-ripple" style={{ borderRadius: '2rem' }}>
                    <span className={item.icon} />
                    <span className="ml-2">{item.label}</span>
                    <Ripple />
                </Link>
            );
        } else if (!item.image) {
            return (
                <Link to={item.path} className="flex align-items-center p-3 cursor-pointer mb-2 gap-2">
                    <span className="inline-flex align-items-center justify-content-center border-circle w-3rem h-3rem">
                        <i className={`${item.image} text-lg`}></i>
                    </span>
                    <span className="inline-flex flex-column gap-1">
                        <span className="font-medium text-lg text-900">{item.label}</span>
                        <span className="white-space-nowrap">{item.subtext}</span>
                    </span>
                </Link>
            );
        } else {
            return (
                <div className="flex flex-column align-items-start gap-2">
                    <div className="flex align-items-center gap-3">
                        <img alt="megamenu-demo" className='w-2rem' src={item.image} />
                        <span>{item.label}</span>
                    </div>
                    <span>{item.subtext}</span>
                </div>
            );
        }
    };

    const start = (
        <Link to="/">
            <img
                src={require("../../assets/images/SPE_white_logo_HD.png")}
                className="logo-custom d-inline-block align-text-top" />
        </Link>
    );

    return (
        <div className="megamenu-container">
            <div className="logo">{start}</div>
            <MegaMenu model={items} orientation="horizontal" breakpoint="960px" className="p-3">
                {loading ? (
                    <ul>
                        {[1, 2, 3, 4, 5].map((index) => (
                            <li key={index}>
                                <div className="flex mb-2">
                                    <Skeleton shape="circle" size="2rem" className='mr-1' />
                                    <Skeleton width="50%" height="30px" />
                                </div>
                                <Skeleton width="100%" height="20px" />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="megamenu-content">
                        <div className="megamenu-column">
                            <ul>
                                {apiproductdetaildata?.slice(0, 4).map((product) => {
                                    let updatedTitle = product.title;

                                    if (product.title?.includes('Management') && product.title?.includes('System')) {
                                        updatedTitle = product.title.replace('System', '').trim();
                                    }

                                    return product.displayOrder === 0 ? null : (
                                        <li key={product.title}>
                                            <Link to={product?.routePath} className='productnamecolor'>
                                                <img className="navbar-logo" src={getLogoForProduct(product.title)} alt={updatedTitle} />
                                                <span>{updatedTitle}</span>
                                                <img className="microsoftbadge" src={require('../../../src/assets/images/Microsoft_Badge_white.png')} alt={updatedTitle} />
                                                <p className='productdescription'>{product.productDescription}</p>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="megamenu-column">
                            <ul>
                                {apiproductdetaildata?.slice(4).map((product) => {
                                    let updatedTitle = product.title;

                                    if (product.title?.includes('Management') && product.title?.includes('System')) {
                                        updatedTitle = product.title.replace('System', '').trim();
                                    }

                                    return product.displayOrder === 0 ? null : (
                                        <li key={product.title}>
                                            <Link to={product?.routePath} className='productnamecolor'>
                                                <img className="navbar-logo" src={getLogoForProduct(product.title)} alt={updatedTitle} />
                                                <span>{updatedTitle}</span>
                                                <img className="microsoftbadge" src={require('../../../src/assets/images/Microsoft_Badge_white.png')} alt={updatedTitle} />
                                                <p className='productdescription'>{product.productDescription}</p>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </MegaMenu>
        </div>
    );
}
