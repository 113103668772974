import React from "react";
import Footer from "../../Footer/Footer";
import ReactHelmetTag from "../../CommonComponents/ReactHelmet/ReactHelmetTag";
import KnowledgeBaseHeading from "../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading";
import Allprojects from "../../CommonComponents/Userguide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const LeaveMSGuide = () => {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/leave-management-system"
        metaKeywords="Introducing leave management system: a powerful solution designed to revolutionize the way organizations handle employee leaves."
      />

      {/* <KnowledgeBaseHeading
        heading="Knowledge Base"
        modalTitle=" Leave Management System"
        title="Download Leave Management System"
        downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200005775"
      /> */}
      <section>
        <LazyLoadComponent>
          <Allprojects project="Leave Management System" />
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default LeaveMSGuide;
