import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};
const images = [
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_1.png"),
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_2.png"),
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_3.png"),
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_4.png"),
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_5.png"),
    require( "../../assets/images/career_at_sharepoint/company_culture_at_sharepoint_6.png"),
];

const CompanyCarousel = ({ deviceType }) => {
    return (
        <Carousel
            ssr
            partialVisbile
            deviceType={deviceType}
            itemClass="image-item"
            responsive={responsive}
            autoPlay
            draggable={false}
            infinite
        >
            {images.slice(0, 12).map(image => {
                return (
                    <Image
                        draggable={true}
                        style={{ width: "90%", height: "100%" }}
                        src={image}
                    />
                );
            })}
        </Carousel>
    );
};

export default CompanyCarousel;
