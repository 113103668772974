import React, { useEffect, useState } from 'react'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import InnerPageBanner from '../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'

import SmallContentCard from '../CommonComponents/ContentCards/SmallContentCard'
import { UiUxDesignCardContentData, UiUxDesignSmallCardData } from './servicesData'
import ContentCards from '../CommonComponents/ContentCards/ContentCards'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import UxCarousel from '../Slider/UxCarousel'
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'

const UIUXDesign = () => {

  const [uiuxdesignsmallcarddata, setUiUxDesignSmallCardData] = useState([])
  const [uiuxdesigncardcontentdata, setUiUxDesignCardContentData] = useState([])

  useEffect(() => {
    document.title = "UI UX design services company";
    setUiUxDesignSmallCardData(UiUxDesignSmallCardData)
    setUiUxDesignCardContentData(UiUxDesignCardContentData)
    window.scrollTo(0, 0)
  })

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/ui-ux-design-services"
        metaTitle="UI UX design services company"
        metaDescription="Elevate user experiences with our UI/UX design and development services. Our expert team combines aesthetics and functionality to create stunning user interfaces and seamless user experiences."
        metaKeywords="ui ux design services, ui ux design services company, ui and ux design services, ui ux design and development services"
      />

      <Navbar />
      <PageHeadingContent heading="UI/UX Design" subtitle="Designing interfaces that seamlessly blend form and function"
        content="We use the latest technologies and frameworks to develop web applications that are performant, scalable, and easy to maintain. Our front-end development team creates intuitive user interfaces that provide a seamless user experience across different devices and platforms. We take into account accessibility and cross-browser compatibility to ensure that our web applications are accessible to all users." />

      <InnerPageBanner BannerImgClass="ui-ux-design-1-img" BannerContent="UI/UX design refers to the process of designing digital products, such as websites, mobile applications, and software interfaces, with a focus on optimizing user experience (UX) and user interface (UI) design. Both UX and UI design are crucial in creating a successful digital product that is easy to use and visually appealing to the user. Effective UI/UX design can improve user engagement, increase user satisfaction, and ultimately lead to better business results." />

      <div className="container-fluid">
        <div className="container">
          <div className="row about-us-inner-card-container kpi-card-row-2-anim" >
            {
              uiuxdesignsmallcarddata.map(ele => {
                return (
                  <SmallContentCard cardTitle={ele.cardTitle} cardSubtitle={ele.cardSubtitle} cardContent={ele.cardContent} />
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="before-row-margin-lm"></div>
         
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="light-mode-title">
                  <h4 className="h4-sontentsubtitle-lm h5-title-anim">
                    Tools we use
                  </h4>
                </div>
              </div>
            </div>
          
         
            <div className="row tech-icon-box-1-anim">
              <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <UxCarousel />
              </div>
            </div>
          
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="before-row-margin-lm"></div>
          <div className="row service-inner-card-container">
           
              <h4 className='h4-sontentsubtitle-lm'>UI/UX Services</h4>
            
            {
              uiuxdesigncardcontentdata.map(ele => {
                return (
                  <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} />
                )
              })
            }
          </div>
        </div>
      </div>

      <InnerPageBanner BannerImgClass="ui-ux-design-2-img" BannerContent="UI/UX design is the process of creating interfaces and experiences that are intuitive, efficient, and satisfying for users. Our UI/UX design services focus on creating exceptional digital experiences for our clients' customers. A good UI/UX design can help improve user engagement, increase conversions, and ultimately drive business success." />

       {/* <CollaborativePartners /> */}
      
      <Footer />
    </div>
  )
}

export default UIUXDesign