import React from 'react'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag';
import KnowledgeBaseHeading from '../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading';
import Allprojects from '../../CommonComponents/Userguide/Allprojects';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const AMSGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/asset-management-system"
                metaKeywords="Asset management system guidelines, Asset management system installation steps, Asset management system help center, Sharepoint asset management system"
            />

            
            {/* <KnowledgeBaseHeading
                heading="Knowledge Base"
                modalTitle=" Asset Management System"
                downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200005193?exp=ubp8"
                title="Download Asset Management System" 
                /> */}

            <section>
                <LazyLoadComponent>
                    <Allprojects project="Asset Management System" />
                </LazyLoadComponent>
            </section >
        </div >
    )
}

export default AMSGuide