import React, { useEffect, useState, useContext } from 'react'
import { useRef } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Modal from 'react-bootstrap/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { CRMAPI } from '../CommonComponents/CommonFuction/CRMAPI'
import ClientsAcrossTheGlobe from '../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import { Link, useHref } from 'react-router-dom'
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext'
import QuotationPDF from './QuotationPDF'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Toast } from 'primereact/toast';

const ContactUs = () => {

    const { apiproductdetaildata } = useContext(ApiDataContext);
    const form = useRef();
    const [show, setShow] = useState(false);

    const titlesAndPlans = apiproductdetaildata?.map(({ title, planDescription, plan }) => {
        const price = parseInt(plan.match(/\d+/)[0], 10);
        return { title, planDescription, price };
    });

    useEffect(() => {
        document.title = "Contact us";
        window.scrollTo(0, 0)
    }, [])

    const [token, setToken] = useState("");
    const captchaRef = useRef(null);
    const [verified, setVerified] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [extraCheckbox, setExtraCheckbox] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userLocation: '',
        userService: [],
        userProject: '',
        CustomizationDetail: [],
        Date: "",
        randomNum: `Q-${Math.floor(Math.random() * 100)}`,
    });

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`;
        setFormData(prevFormData => ({
            ...prevFormData,
            Date: formattedDate
        }));
    }, []);

    useEffect(() => {
        if (token && token.length > 0) {
            setVerified(false)
        }
    }, [token]);

    const handleClose = () => setShow(false);

    const resetCaptcha = () => {
        if (captchaRef.current) {
            captchaRef.current.resetCaptcha();
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    const generatePDFAndDownload = async () => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date();
        let currentmonth = month[d.getMonth()];
        let currentyear = d.getFullYear();
        const blob = await pdf((<QuotationPDF formData={formData} />)).toBlob();
        const pdfName = `M365_${formData.userCompany.replace(/\s/g, '_')}_Quotation_${currentmonth}_${currentyear}.pdf`;

        await saveAs(blob, pdfName);

        const data = {
            name: formData.userName,
            email: formData.userEmail,
            company: formData.userCompany,
            location: formData.userLocation,
            service: formData.userService?.map(service => service.title).join(', '),
            description: `Quotation downloaded by ${formData.userName} /Total Amount: ${formData.totalPrice} USD /\n${formData.userProject}`,
        };

        await CRMAPI(data);

    };
    const validateForm = async () => {
        let formValid = true;
        setSubmitted(true);
        if (!formData.userName || !isValidEmail(formData.userEmail) || !formData.userCompany || !formData.userLocation || !formData.userService.length > 0 || token == "") {
            formValid = false;
        }

        if (extraCheckbox === false && !formData.userProject) {
            formValid = false;
        }

        if (!formValid) {
            return;
        }

        setShow(true)
        if (formValid) {
            setTimeout(() => {
                setShow(false)
                form.current.reset();
                resetCaptcha();
                setFormData({
                    userName: '',
                    userEmail: '',
                    userCompany: '',
                    userLocation: '',
                    userService: [],
                    userProject: '',
                });

                setToken('');
                setSubmitted(false);
            }, 3000);
        }
        if (extraCheckbox == true) {
            generatePDFAndDownload();

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json;odata=verbose");
            myHeaders.append("Authorization", "Bearer 4352345342");

            const apiData = {
                "Title": formData.userName,
                "EmailAddress": formData.userEmail,
                "Companyname": formData.userCompany,
                "Companylocation": formData.userLocation,
                "Total": formData.totalPrice,
                "SelectType": "Quotation",
                "Date": formData.Date,
                "Discount": "0",
                "Note": "This Quotation is valid for 7 Days from the date of issuance.",
                "BillDuration": "Yearly",
                "BalanceOwned": formData.totalPrice,
                "MoveToClientTracker": "False",
                "CurrencyId": "1",
                "CustomizationDetail": JSON.stringify(formData.CustomizationDetail),
                "Price": JSON.stringify(formData.userService),
                "RandomBillNO": formData.randomNum
            };

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(apiData),
            };

            fetch("https://api.sharepointempower.com/api/sharepoint/postquotationdata", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

            setExtraCheckbox(false);
        } else {
            const data = {
                name: formData.userName,
                email: formData.userEmail,
                company: formData.userCompany,
                location: formData.userLocation,
                service: formData.userService?.map(service => service.title).join(', '),
                description: formData.userProject
            }
            await CRMAPI(data);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const handleCheckboxChange = (e, selectedService) => {
        const { checked } = e.target;
        const updatedServices = checked
            ? [...formData.userService, selectedService]
            : formData.userService.filter(service => service.title !== selectedService.title);

        const updatedCustomizationDetail = updatedServices.map(service => ({
            Customization: `M365 - ${service.title} * ${service.planDescription}`,
            Charges: service.price * 12
        }));

        const totalPrice = updatedServices?.reduce((total, service) => total + service.price * 12, 0);

        setFormData(prevFormData => ({
            ...prevFormData,
            userService: updatedServices,
            CustomizationDetail: updatedCustomizationDetail,
            totalPrice,
            balanceOwned: totalPrice
        }));
    };


    return (
        <div className='mt-7'>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/contact-us"
                metaKeywords="contact us form"
            />

            <Navbar />
            <div className="fixed-top-container">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-9 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title">
                                    <h1 className="h1-tag-lm first-h1-anim h1-heading">Contact US</h1>
                                </div>
                            </div>
                            <div className="col col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 flex justify-content-end align-items-center">
                                <div id="first-section-margin-top-for-navbar" className="light-mode-title quotationbtn d-flex">
                                    <Link to="/faqs" className='pricingfaq'>
                                        <i className="fa fa-circle-question mr-1" />
                                        <span className='m-0'>FAQs</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div className="container-fluid">
                    <div className="container mt-4">
                        <div className="before-row-margin-lm"></div>
                        <div className="form-container form-container-anim py-0">
                            <div className="row">
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-0">
                                    <form ref={form} name="ContactForm" autoComplete='off'
                                        className="contact-us-form form-data-anim">
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="fname">Name :</label>
                                                <input type="text" id="fname" name="userName" onChange={handleChange} required />
                                                {submitted && !formData?.userName && <small className="p-error"> Please enter your name *</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="email">Company Email Address :</label>
                                                <input type="text" id="email" name="userEmail" onChange={handleChange} required />
                                                {submitted && !formData?.userEmail && <small className="p-error"> Please enter your valid email *</small>}
                                                {submitted && formData?.userEmail && !isValidEmail(formData.userEmail) && <span className="p-error">Invalid email address.</span>}

                                            </div>
                                        </div>
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="company">Your Company :</label>
                                                <input type="text" id="company" name="userCompany" onChange={handleChange} required />
                                                {submitted && !formData?.userCompany && <small className="p-error"> Please enter your company name *</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="location">Your Location :</label>
                                                <input type="text" id="location" name="userLocation" onChange={handleChange} required />
                                                {submitted && !formData?.userLocation && <small className="p-error"> Please enter your location *</small>}
                                            </div>
                                        </div>

                                        <label htmlFor="service">What kind of services/products are you looking for? :</label>{submitted && !formData?.userService.length > 0 && <small className="p-error"> Please select product *</small>}
                                        <br></br>
                                        <div className={window.innerWidth > 1081 ? 'flex' : ''}>
                                            {/* First column */}
                                            <div style={{ flex: 1, flexBasis: 30 }}>
                                                {titlesAndPlans?.slice(0, Math.ceil(titlesAndPlans.length / 2)).map(service => (
                                                    <div key={service.title}>
                                                        <input
                                                            type="checkbox"
                                                            id={service.title}
                                                            name={service.title}
                                                            value={service.title}
                                                            checked={formData?.userService?.some(item => item.title === service.title)}
                                                            onChange={(e) => handleCheckboxChange(e, service)}
                                                        />
                                                        <label htmlFor={service.title} className='ml-2'>{service.title}</label>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Second column */}
                                            <div style={{ flex: 1 }}>
                                                {titlesAndPlans?.slice(Math.ceil(titlesAndPlans.length / 2)).map(service => (
                                                    <div key={service.title}>
                                                        <input
                                                            type="checkbox"
                                                            id={service.title}
                                                            name={service.title}
                                                            value={service.title}
                                                            checked={formData.userService.some(item => item.title === service.title)}
                                                            onChange={(e) => handleCheckboxChange(e, service)}
                                                        />
                                                        <label htmlFor={service.title} className='ml-2'>{service.title}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='quotationcheckbox'>
                                            <input
                                                type="checkbox"
                                                id="extraCheckbox"
                                                name="extraCheckbox"
                                                checked={formData.extraCheckbox}
                                                onChange={() => setExtraCheckbox(true)}
                                            />
                                            <label htmlFor="extraCheckbox" className='ml-2'> <b> <mark> Need a Quotation? </mark> </b></label>
                                        </div>
                                        <label htmlFor="project">Tell us more about your requirements : {submitted && !extraCheckbox && !formData.userProject && <small className="p-error"> Please write your requirements *</small>}</label>
                                        <textarea className="form-control text-area" onChange={handleChange} name='userProject' id="exampleFormControlTextarea1" rows="2" required></textarea>
                                        <br></br>
                                        <div className='flex justify-content-between align-items-center contact-btn-flex'>
                                            <div className='flex flex-column'>
                                                <HCaptcha
                                                    sitekey="abfb95a6-010a-4caf-af7b-7852f11d3e2e"
                                                    id="captcha" name="user_captcha" onChange={handleChange}
                                                    // onLoad={onLoad}
                                                    // onChange={onChange}
                                                    onVerify={setToken}
                                                    ref={captchaRef}
                                                    theme='dark'
                                                />
                                                {submitted && !token && <small className="p-error"> Please fill captcha code *</small>}
                                            </div>

                                            <button type='button' id="btn-submit" disabled={verified} onClick={validateForm} className="btn-gradient">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="company-details-container">
                                        <ul>
                                            <div className='row'>
                                                <div className='col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 col-xxl-6'>
                                                    <li>
                                                        <a href='https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf' target='_blank'>
                                                            <LazyLoadImage
                                                                className="microsoft-icon bg-black"
                                                                src={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                                alt="Microsoft-solution-partner"
                                                                effect="blur"
                                                                visibleByDefault={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                            />
                                                        </a>
                                                    </li>
                                                </div>
                                                <div className='col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-6 col-xxl-6 mt-2'>
                                                    <li><b> Contact Information</b></li>
                                                    <li className='text-md'><a href="tel:9429048910">+91 9429048910</a></li>
                                                    <li className='text-md'><a href="mailto:support@sharepointempower.com">support@sharepointempower.com</a></li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="form-img-container form-data-anim pt-4">
                                        <LazyLoadImage
                                            src={require("../../assets/images/contact-us/form_image.png")}
                                            alt="contact-us-form"
                                            effect="blur"
                                            visibleByDefault={require("../../assets/images/contact-us/form_image.png")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <Modal show={show} onHide={handleClose} centered className='success-modal-body'>
                <img src={require("../../assets/images/icons/accept-icon.png")} className='sent-mail-alert-image' alt='Success' />
            </Modal>
            <ClientsAcrossTheGlobe />
            <Footer />
        </div >
    )
}

export default ContactUs