import React from "react";
import Footer from "../../Footer/Footer";
import ReactHelmetTag from "../../CommonComponents/ReactHelmet/ReactHelmetTag";
import KnowledgeBaseHeading from "../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading";
import AllProjects from "../../CommonComponents/Userguide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const SPEIntranetGuide = () => {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/spe-intranet"
        metaKeywords="Our SPE Intranet management system also facilitates seamless collaboration among team members. It offers features like Document libraries, Sort & searching functionality, and system customization, allowing employees to work together efficiently."
      />

      {/* <KnowledgeBaseHeading
        heading="Knowledge Base"
        modalTitle=" SPE Intranet"
        title="Download SPE Intranet"
        downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200005709"
      /> */}

      <section>
        <LazyLoadComponent>
          <AllProjects project="SPE Intranet" />
        </LazyLoadComponent>
      </section>
    </div>
  );
};

export default SPEIntranetGuide;
