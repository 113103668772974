import React, { useEffect, useState } from 'react'
import MasterPage from './components/MasterPage/MasterPage';
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import Snowfall from 'react-snowfall';
import OpenAIChatWidget from './components/ChatBot/ChatBot';

function App() {

  const [showSnowfall, setShowSnowfall] = useState(true);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startdate = new Date(`${currentYear}-12-24T00:00:00`);
    const enddate = new Date(startdate);
    enddate.setDate(startdate.getDate() + 10);

    const now = new Date();
    if (now >= startdate && now <= enddate) {
      setShowSnowfall(true);
    } else {
      setShowSnowfall(false);
    }
  }, []);

  return (
    <div>
      <MasterPage />
      {/* <OpenAIChatWidget/> */}
      <div>
        {showSnowfall && (
          <Snowfall snowflakeCount={200} speed={[0.5, 1]} radius={[0.5, 1.5]} color='#BEDCFF' wind={[0, 0.5]} style={{
            position: 'fixed',
          }} />
        )}
      </div>
    </div>
  );
}

export default App;
