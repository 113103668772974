import React from 'react'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag';
import KnowledgeBaseHeading from '../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading';
import Allprojects from '../../CommonComponents/Userguide/Allprojects';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const ASGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/approval-system"
                metaKeywords="aprroval System, microsoft approval syatem, microsoft 365 approval system, m365 approval system, ticket generate "
            />

            {/* <KnowledgeBaseHeading
                heading="Knowledge Base"
                modalTitle=" Approval System"
                downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200006514?exp=ubp8&tab=Overview"
                title="Download Approval System" /> */}
            <section>
                <LazyLoadComponent>
                    <Allprojects project="Approval System" />
                </LazyLoadComponent>
            </section >
        </div >
    )
}

export default ASGuide