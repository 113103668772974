import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const SmallContentCard = (props) => {
    return (
        <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="about-us-inner-card-body d-flex-center-custom">
                    <span className="about-us-inner-card-title">{props.cardTitle}</span>
                    <span className="about-us-inner-card-subtitle">{props.cardSubtitle}</span>
                    <p className="about-us-inner-card-content">
                        {props.cardContent}
                    </p>
                </div>
        </div>
    )
}

export default SmallContentCard