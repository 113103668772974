import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Modal from 'react-bootstrap/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { CRMAPI } from '../CommonComponents/CommonFuction/CRMAPI';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import QuotationPDF from './QuotationPDF';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const Quotation = () => {
    const { apiproductdetaildata } = useContext(ApiDataContext);

    const titlesAndPlans = apiproductdetaildata?.map(({ title, planDescription, plan }) => {
        const price = parseInt(plan.match(/\d+/)[0], 10);
        return { title, planDescription, price };
    });

    useEffect(() => {
        document.title = "Get Quotation";
        window.scrollTo(0, 0);
    }, []);

    const [token, setToken] = useState("");
    const captchaRef = useRef(null);
    const form = useRef();
    const [verified, setVerified] = useState(true);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userLocation: '',
        userService: [],
        CustomizationDetail: [],
        Date: "",
        randomNum: `Q-${Math.floor(Math.random() * 100)}`,
    });

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`;
        setFormData(prevFormData => ({
            ...prevFormData,
            Date: formattedDate
        }));
    }, []);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (token && token.length > 0) {
            setVerified(false);
        }
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e, selectedService) => {
        const { checked } = e.target;
        const updatedServices = checked
            ? [...formData.userService, selectedService]
            : formData.userService.filter(service => service.title !== selectedService.title);

        const updatedCustomizationDetail = updatedServices.map(service => ({
            Customization: `M365 - ${service.title} * ${service.planDescription}`,
            Charges: service.price * 12
        }));

        const totalPrice = updatedServices?.reduce((total, service) => total + service.price * 12, 0);

        setFormData(prevFormData => ({
            ...prevFormData,
            userService: updatedServices,
            CustomizationDetail: updatedCustomizationDetail,
            totalPrice,
            balanceOwned: totalPrice
        }));
    };

    const resetCaptcha = () => {
        if (captchaRef.current) {
            captchaRef.current.resetCaptcha();
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSubmit = async () => {
        let formValid = true;
        setSubmitted(true);
        if (!formData.userName || !isValidEmail(formData.userEmail) || !formData.userCompany || !formData.userLocation || !formData.userService || token == "") {
            return;
        }
        if (formValid) {
            await generatePDFAndDownload();

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json;odata=verbose");
            myHeaders.append("Authorization", "Bearer 4352345342");


            const apiData = {
                "Title": formData.userName,
                "EmailAddress": formData.userEmail,
                "Companyname": formData.userCompany,
                "Companylocation": formData.userLocation,
                "Total": formData.totalPrice,
                "SelectType": "Quotation",
                "Date": formData.Date,
                "Discount": "0",
                "Note": "This Quotation is valid for 7 Days from the date of issuance.",
                "BillDuration": "Yearly",
                "BalanceOwned": formData.totalPrice,
                "MoveToClientTracker": "False",
                "CurrencyId": "1",
                "CustomizationDetail": JSON.stringify(formData.CustomizationDetail),
                "Price": JSON.stringify(formData.userService),
                "RandomBillNO": formData.randomNum
            };


            console.log("apidatra", apiData)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(apiData),
            };
            console.log("apidata", requestOptions.body)

            fetch("http://localhost:5220/api/sharepoint/postquotationdata", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

            setShowSuccessModal(true);
            setTimeout(() => {
                setShowSuccessModal(false);
                form.current.reset();
                resetCaptcha();
                setFormData({
                    userName: '',
                    userEmail: '',
                    userCompany: '',
                    userLocation: '',
                    userService: [],
                    selectType: "",
                    Date: "",
                    Discount: "",
                    Note: "",
                    BillDuration: "",
                    Currency: "",
                    totalPrice: 0,
                    balanceOwned: 0,
                    moveToClientTracker: "",
                    CustomizationDetail: [],
                });
                setToken('');
                setSubmitted(false);
            }, 3000);
        }
    }

    const generatePDFAndDownload = async () => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date();
        let currentmonth = month[d.getMonth()];
        let currentyear = d.getFullYear();
        const blob = await pdf((<QuotationPDF formData={formData} />)).toBlob();
        const pdfName = `M365_${formData.userCompany.replace(/\s/g, '_')}_Quotation_${currentmonth}_${currentyear}.pdf`;

        await saveAs(blob, pdfName);

        const data = {
            name: formData.userName,
            email: formData.userEmail,
            company: formData.userCompany,
            location: formData.userLocation,
            service: formData.userService?.map(service => service.title).join(', '),
            description: 'Quotation',
        };
        // await CRMAPI(data);
    };

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/quotation"
                metaKeywords="Quotation form"
            />
            <Navbar />
            <div className='mt-7'>
                <PageHeadingContent heading="Quotation" content="Experience personalized solutions through our quotes. We prioritize understanding your needs to deliver accurate and timely quotations, empowering you to make informed decisions effortlessly." />
            </div>
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="before-row-margin-lm"></div>
                        <div className="form-container form-container-anim row pt-0">
                            <div className="row">
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-0">
                                    <form ref={form} name="QuotationForm" autoComplete="off" className="contact-us-form form-data-anim">
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="fname">Name :</label>
                                                <input type="text" id="fname" name="userName" onChange={handleInputChange} required />
                                                {submitted && !formData?.userName && <small className="p-error"> Please enter your name *</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="email">Email Address :</label>
                                                <input type="text" id="email" name="userEmail" onChange={handleInputChange} required />
                                                {submitted && !formData?.userEmail && <small className="p-error"> Please enter your valid email *</small>}
                                                {submitted && formData?.userEmail && !isValidEmail(formData.userEmail) && <span className="p-error">Invalid email address.</span>}
                                            </div>
                                        </div>
                                        <div className='flex row'>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="company">Your Company :</label>
                                                <input type="text" id="company" name="userCompany" onChange={handleInputChange} required />
                                                {submitted && !formData?.userCompany && <small className="p-error"> Please enter your company name *</small>}
                                            </div>
                                            <div className='col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                <label htmlFor="location">Your Location :</label>
                                                <input type="text" id="location" name="userLocation" onChange={handleInputChange} required />
                                                {submitted && !formData?.userLocation && <small className="p-error"> Please enter your location *</small>}
                                            </div>
                                        </div>
                                        <label htmlFor="service">What kind of services/products are you looking for? :</label>{submitted && !formData?.userService.length && <small className="p-error"> Please Select Products *</small>}
                                        <br />
                                        <div className={window.innerWidth > 1081 ? 'flex' : ''}>
                                            {/* First column */}
                                            <div style={{ flex: 1, flexBasis: 30 }}>
                                                {titlesAndPlans?.slice(0, Math.ceil(titlesAndPlans.length / 2)).map(service => (
                                                    <div key={service.title}>
                                                        <input
                                                            type="checkbox"
                                                            id={service.title}
                                                            name={service.title}
                                                            value={service.title}
                                                            checked={formData.userService.some(item => item.title === service.title)}
                                                            onChange={(e) => handleCheckboxChange(e, service)}
                                                        />
                                                        <label htmlFor={service.title} className='ml-2'>{service.title}</label>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Second column */}
                                            <div style={{ flex: 1 }}>
                                                {titlesAndPlans?.slice(Math.ceil(titlesAndPlans.length / 2)).map(service => (
                                                    <div key={service.title}>
                                                        <input
                                                            type="checkbox"
                                                            id={service.title}
                                                            name={service.title}
                                                            value={service.title}
                                                            checked={formData.userService.some(item => item.title === service.title)}
                                                            onChange={(e) => handleCheckboxChange(e, service)}
                                                        />
                                                        <label htmlFor={service.title} className='ml-2'>{service.title}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <br />
                                        <div className='flex justify-content-between align-items-center contact-btn-flex'>
                                            <div className='flex flex-column'>
                                                <HCaptcha
                                                    sitekey="abfb95a6-010a-4caf-af7b-7852f11d3e2e"
                                                    id="captcha"
                                                    name="user_captcha"
                                                    onVerify={setToken}
                                                    ref={captchaRef}
                                                    theme='dark'
                                                />
                                                {submitted && !token && <small className="p-error"> Please fill captcha code *</small>}
                                            </div>
                                            <button type='button' id="btn-submit" disabled={verified} onClick={handleSubmit} className="btn-gradient btn-contact-us">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="company-details-container">
                                        <ul>
                                            <div className='row'>
                                                <div className='col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 col-xxl-6'>
                                                    <li>
                                                        <a href='https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf' target='_blank'>
                                                            <LazyLoadImage
                                                                className="microsoft-icon bg-black"
                                                                src={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                                alt="Microsoft-solution-partner"
                                                                effect="blur"
                                                                visibleByDefault={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                            />
                                                        </a>
                                                    </li>
                                                </div>
                                                <div className='col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-6 col-xxl-6 mt-2'>
                                                    <li><b> Contact Information</b></li>
                                                    <li className='text-md'><a href="tel:9429048910">+91 9429048910</a></li>
                                                    <li className='text-md'><a href="mailto:support@sharepointempower.com">support@sharepointempower.com</a></li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="form-img-container form-data-anim pt-4">
                                        <LazyLoadImage
                                            src={require("../../assets/images/contact-us/form_image.png")}
                                            alt="contact-us-form"
                                            effect="blur"
                                            visibleByDefault={require("../../assets/images/contact-us/form_image.png")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered className='success-modal-body'>
                <img src={require("../../assets/images/icons/accept-icon.png")} className='sent-mail-alert-image' alt='Success' />
            </Modal>

            <Footer />
        </div >
    );
};

export default Quotation;
