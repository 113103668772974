import React, { useEffect, useState, useContext } from 'react'
import Navbar from '../../Navbar/Navbar'
import './ProductPage.css'
import useLocalStorage from 'use-local-storage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'
import { ApiDataContext } from '../../CommonComponents/Userguide/ApiDataContext'
import { Link } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import ReactImageZoom from 'react-image-zoom';
import { Dialog } from 'primereact/dialog';
import ClientsAcrossTheGlobe from '../../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe';
import Footer from '../../Footer/Footer';

const ProductPage = ({ project }) => {

  const { apiProductPagedata } = useContext(ApiDataContext);

  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = apiProductPagedata?.filter((item) => item.projectTitle === project);
  let updateprojectname = data[0]?.projectTitle;

  if (data[0]?.projectName?.includes('Management') && data[0]?.projectTitle?.includes('System')) {
    updateprojectname = data[0]?.projectTitle.replace('System', '').trim();
  }

  useEffect(() => {
    applyTheme(theme)
  }, [theme]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const applyTheme = (selectedTheme) => {
    const element = document.body;
    element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
  }

  function getLogoForProduct(productName) {

    switch (productName) {
      case 'Learning Management Portal':
        return theme === 'light' ? require('../../../assets/images/icons/LMP-w.png') : require('../../../assets/images/icons/LMP-b.png');
      case 'Asset Management System':
        return theme === 'light' ? require('../../../assets/images/icons/AMS-w.png') : require('../../../assets/images/icons/AMS-b.png');
      case 'Audit Inspection':
        return theme === 'light' ? require('../../../assets/images/icons/audit-w.png') : require('../../../assets/images/icons/audit-b.png');
      case 'Construction Management System':
        return theme === 'light' ? require('../../../assets/images/icons/CMS-w.png') : require('../../../assets/images/icons/CMS-b.png');
      case 'SPE Intranet':
        return theme === 'light' ? require('../../../assets/images/icons/spe-Intranet-w.png') : require('../../../assets/images/icons/spe-Intranet-b.png');
      case 'Helpdesk System':
        return theme === 'light' ? require('../../../assets/images/icons/HD-w.png') : require('../../../assets/images/icons/HD-b.png');
      case 'Leave Management System':
        return theme === 'light' ? require('../../../assets/images/icons/leave-ms-w.png') : require('../../../assets/images/icons/leave-ms-b.png');
      case 'Inventory Management System':
        return theme === 'light' ? require('../../../assets/images/icons/IMS-w.png') : require('../../../assets/images/icons/IMS-b.png');
      case 'Approval System':
        return theme === 'light' ? require('../../../assets/images/icons/AS-w.png') : require('../../../assets/images/icons/AS-b.png');
      default:
        return null;
    }
  }

  const zoomPropsProductKnowImg = {
    width: 600,
    zoomWidth: 500,
    scale: 1.1,
    zoomPosition: 'left',
  };

  const zoomPropsProductImage = {
    width: 600,
    zoomWidth: 500,
    scale: 1.1,
    zoomPosition: 'right',
  };

  // const handleImagehover = () => {
  //   if (!localStorage.getItem('productimagehoverKey')) {
  //     setIsModalOpen(true);
  //     localStorage.setItem('productimagehoverKey', 'hovered');
  //   }
  // };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div>
        {/* Product content */}
        <div className="container-fluid productpage">
          {data?.length > 0 ? (
            <div className='container'>
              {/* First section */}
              <div className='flex flex-wrap justify-content-between align-items-center first-section-margin-top-for-navbar'>
                <div className='flex flex-wrap align-items-center'>
                  <img className="productIcon" src={getLogoForProduct(data[0]?.projectTitle)} alt="" />
                  <h2 className='ProductName'>{updateprojectname}</h2>
                  <a href={data[0]?.certifiedLink} target='_blank'><img className='certifiedImage' src={data[0]?.certifiedImage} alt="" ></img></a>
                </div>
                <div className='quotationbtn flex align-items-center'>
                  <Link to="/faqs" className='pricingfaq'>
                    <i className="fa fa-circle-question mr-1" />
                    <span className='m-0'>FAQs</span>
                  </Link>
                </div>
              </div>
              {/* Second section */}
              <div className='mt-5 productpagebackground'>
                <div className='row productknow'>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                    <div>
                      <p>{data[0]?.productKnowDescription}</p>
                      {window.innerWidth >= 450 ? <div className='my-3 mx-2 productKnowbutton icon-btn row'>
                        <a className='m-3 mx-0 text-lg p-3 col-5 col-md-3 col-lg-3' href={data[0]?.download} target="_blank">
                          <i className="fa-solid fa-download px-2"></i>Download
                        </a>
                        <Link className='m-3 mx-0 text-lg p-3 col-5 col-md-3 col-lg-3' to={data[0]?.helpCenter}>
                          <i className="fa-solid fa-info px-2"></i>Help Center
                        </Link>
                        {data[0]?.certifiedLink && (
                          <a className='m-3 mx-0 text-lg p-3 col-5 col-md-3 col-lg-3' href={data[0]?.productCertificateLink} target="_blank">
                            <i className="fa-solid  fa-award px-2"></i>Award
                          </a>
                        )}
                      </div>
                        :
                        <div className='productKnowbutton flex justify-content-center'>
                          <a className='px-3 py-2 w-3' href={data[0]?.download} target="_blank">
                            <i className="fa-solid fa-download text-xl"></i>
                          </a>
                          <Link className='px-3 py-2 w-3' to={data[0]?.helpCenter}>
                            <i className="fa-solid fa-info text-xl"></i>
                          </Link>
                          {data[0]?.certifiedLink && (
                            <a className='px-3 py-2 w-3' href={data[0]?.productCertificateLink} target="_blank">
                              <i className="fa-solid  fa-award text-xl"></i>
                            </a>
                          )}
                        </div>}
                    </div>
                  </div>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 m-0 flex-column' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <LazyLoadImage
                      className="product_img"
                      src={data[0]?.productKnowImage}
                      alt="LMP-banner"
                      delayTime={100}
                      loading='lazy'
                      onClick={handleImageClick}
                      visibleByDefault={data[0]?.productKnowImage}
                    />
                    <Dialog visible={isModalOpen} onHide={() => setIsModalOpen(false)} dismissableMask={() => setIsModalOpen(true)} maximizable >
                      <img src={data[0]?.productKnowImage} alt="Full-sized" style={{ width: '100%', height: 'auto' }} />
                    </Dialog>
                  </div>
                </div>
              </div>
              {/* Third section */}
              <div className='p-4'>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'>Why&nbsp;{data[0]?.projectTitle}?</h4>
                <p className='productfontcolor descriptionfontsize' dangerouslySetInnerHTML={{ __html: data[0]?.productDescription }}></p>
                <div className='row mt-4'>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {window.innerWidth >= 450 ? <ReactImageZoom className="product_img" {...zoomPropsProductImage} img={data[0]?.productImage} /> :
                      <LazyLoadImage
                        className="product_img"
                        loading='lazy'
                        src={data[0]?.productImage}
                        alt={data[0]?.productImage}
                        delayTime={100}
                        visibleByDefault={data[0]?.productImage}
                      />
                    }
                  </div>
                  <div className='col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 productpoint'>
                    <p className='productfontcolor px-2' dangerouslySetInnerHTML={{ __html: data[0]?.productPoints }}></p>
                  </div>
                </div>
              </div>
              {/* Fourth section */}
              <div className="container spfxnote">
                <span>Our apps are developed using modern SharePoint Framework-based solutions, and futuristics, and It is modern technology that will be supported in the future and recommended by Microsoft. </span>
                <span>Sharepoint-hosted and Provider-hosted apps will be discontinued in the future. However, our app is already <b> modern SharePoint Framework Solutions </b> as recommended by <b>Microsoft.</b></span>
              </div>
              {/* Fifth section */}
              <div className='p-4'>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'>Top Features</h4>
                <div className='row d-flex justify-content-center product-feature-row-1-anim'>
                  {JSON.parse(data[0]?.features)?.map((e) => (
                    <div className='col col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center border-1 m-3 productpagebackground'>
                      <div className='py-4'>
                        <img src={`https://www.sharepointempower.com/assets/Images/product-page-images/${e?.icon}`} alt="" />
                        <p className='mb-0'>{e.name}</p>
                        <p className='mt-0'>{e.subtitle}</p>
                      </div>
                    </div>))}
                </div>
              </div>
              {/* Sixth section */}
              <div className='p-4 certified'>
                <h4 className='d-flex justify-content-center h5-title-lm text-align-center h5-title-anim'> {data[0]?.certifiedHeader}</h4>
                <h5 className='d-flex justify-content-center'>{data[0]?.certifiedSubHeader}</h5>
                <p className='mt-5 descriptionfontsize'>{data[0]?.certifiedDescription}</p>
              </div>
            </div>
          ) : (
            // Skeleton loading placeholders
            <>
              <div className='container'>
                <div className=' flex flex-wrap first-section-margin-top-for-navbar align-items-center'>
                  <Skeleton shape='circle' size='2.5rem' className='m-1' />
                  <Skeleton width="20%" height="30px" className='m-1' />
                  <Skeleton width="5%" height="20px" className='m-1' />
                </div>

                <div className='mt-5 productpagebackground'>
                  <div className='row productknow'>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                      <div>
                        <Skeleton width="70%" height="150px" className='m-1' />
                        <div className='my-5 productKnowbutton row'>
                          <Skeleton width="15%" height="40px" className='m-1' />
                          <Skeleton width="15%" height="40px" className='m-1' />
                          <Skeleton width="15%" height="40px" className='m-1' />
                        </div>
                      </div>
                    </div>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 m-0 ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Skeleton width="100%" height="250px" className='m-1' />
                    </div>
                  </div>
                </div>
                <div className='p-4'>
                  <div className='d-flex justify-content-center text-align-center'>
                    <Skeleton width="50%" height="40px" className='m-1' />
                  </div>
                  <Skeleton width="100%" height="90px" className='m-3 px-2' />
                  <div className='row mt-4'>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Skeleton width="80%" height="250px" className='m-1' />
                    </div>
                    <div className='col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 productpoint'>
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                      <Skeleton width="70%" height="20px" className='m-3 px-2' />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Divider type="solid" className='container m-auto opacity-50' />
      <ClientsAcrossTheGlobe />
      <Footer />
    </>
  )
}

export default ProductPage