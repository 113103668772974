import React, { useEffect } from 'react'
import SmallFooterBanner from '../../CommonComponents/BackgroundBanners/SmallFooterBanner/SmallFooterBanner'
import CollaborativePartners from '../../CommonComponents/CollaborativePartners/CollaborativePartners'
import CaseStudies from './CaseStudies'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'

const CustomSoftwareDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/custom-software-development"
                metaKeywords="Custom software developement service, Software developement services"
            />

            <Navbar />
            <CaseStudies />
             {/* <CollaborativePartners /> */}
            
            <Footer />
        </div>
    )
}

export default CustomSoftwareDevelopment