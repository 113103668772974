import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactHelmetTag from "../CommonComponents/ReactHelmet/ReactHelmetTag";
import ProductPage from "./ProductPage/ProductPage";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const ApprovalSystem = () => {

  useEffect(() => {
    document.title = "Approval System";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/approval-system"
        metaTitle="Sharepoint Digital approval System"
        metaDescription="An approval system is a structured process in organizations for reviewing, authorizing, and tracking requests or decisions. It ensures that actions align with established protocols and policies, promoting transparency and accountability. Features like role-based access control and automated workflows enhance efficiency and documentation within the approval process."
        metaKeywords="approval system, approval software, digital approval system, Sharepoint approval system, sharepoint digital approval management, microsoft 365 approval system sharepoint, sharepoint approval management system, sharepoint digital approval management system, m365 approval system"
      />

      <Navbar />
      <LazyLoadComponent>
        <ProductPage project="Approval System" />
      </LazyLoadComponent>
    </div>
  );
};

export default ApprovalSystem;
