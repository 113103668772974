import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const ContentCards = (props) => {

    return (
        <div className="service-inner-card-1-anim col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <div className="service-inner-card-body d-flex-center-custom">
                    <img className="card-line-vector" src={require("../../../assets/images/service_inner_page/line_design_for_card.png")}
                        alt="line_design_for_card" />
                    {/* <img className="card-price-badge" src={require("../../../assets/images/icons/product-pricing-badge.png")}
                        alt="line_design_for_card" /> */}
                    <img className="card-icon-vector" src={require("../../../assets/images/service_inner_page/card-icon-vector.png")}
                        alt="card-icon-vector" />
                    <span className="service-inner-card-title">{props.cardTitle}</span>
                    <p className="service-inner-card-content">
                        {props.cardContent}
                    </p>
                    {props.button}
                </div>
        </div>
    )
}

export default ContentCards