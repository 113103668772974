import React, { useEffect, useContext, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from './Footer';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import './FAQ.css';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import CustomDropdown from '../CommonComponents/CustomeDropdown/CustomDropdown';

function FAQ({ project }) {
    const { apiFAQdata } = useContext(ApiDataContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [accordionClicked, setAccordionClicked] = useState(false);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [labels, setLabels] = useState([]);

    const commonfaq = apiFAQdata?.filter(item => item.projectName === null);

    useEffect(() => {
        const extractLabels = (data) => {
            const labels = new Set();
            data?.forEach(item => {
                const faQtags = item.faQtags || '[]';
                try {
                    const tags = JSON.parse(faQtags);
                    tags.forEach(tag => {
                        labels.add(tag.Label);
                    });
                } catch (e) {
                    console.error('Error parsing faQtags:', e);
                }
            });
            return Array.from(labels);
        };

        const extractedLabels = extractLabels(apiFAQdata);
        setLabels(extractedLabels);
    }, [apiFAQdata]);

    useEffect(() => {
        document.title = 'FAQs';

        const handleHashChange = () => {
            if (!accordionClicked) {
                const id = window.location.hash.replace('#', '');
                const formattedId = id.replace(/-/g, ' ');
                const formattedIdWithQuestionMark = formattedId.endsWith('?') ? formattedId : `${formattedId}?`;

                if (formattedIdWithQuestionMark) {
                    const faqItem = commonfaq.find(item => item.faqQuestion === formattedIdWithQuestionMark);
                    if (faqItem) {
                        const index = commonfaq.indexOf(faqItem);
                        setActiveIndex(index >= 0 ? index : null);

                        const faqElement = document.getElementById(`${formattedIdWithQuestionMark}`);

                        if (faqElement) {
                            const faqScrollElement = document.querySelector('.faqscroll');
                            const headerHeight = document.querySelector('.faqheader').offsetHeight;
                            faqScrollElement.scrollTo({
                                top: faqElement.offsetTop - headerHeight,
                                behavior: 'smooth',
                            });
                        }
                    }
                }
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [commonfaq, accordionClicked]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const clearSearch = () => {
        setSearchQuery('');
    };

    const filterFAQs = (faqItems) => {
        return faqItems.filter((faqItem) => {
            const matchesSearchQuery = faqItem.faqQuestion.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesLabel = selectedLabel === '' || (faqItem.faQtags && JSON.parse(faqItem.faQtags).some(tag => tag.Label === selectedLabel));
            return matchesSearchQuery && matchesLabel;
        });
    };

    const copyToClipboard = (text, index) => {
        const modifiedText = text?.slice(0, -1);
        setActiveIndex(activeIndex === index ? index : null);
        navigator.clipboard.writeText(modifiedText);
        setShowCopyMessage(true);
        setTimeout(() => {
            setShowCopyMessage(false);
        }, 2000);
    };


    const createDynamicTabs = (faqItems) => {
        return faqItems.map((tab, i) => {
            const faqId = `${tab.faqQuestion}`;
            const faqUrl = `${window.location.origin}${window.location.pathname}#${faqId.replace(/\s/g, '-')}`;

            return (
                <AccordionTab
                    key={tab.faqQuestion}
                    id={accordionClicked === false && showCopyMessage === false ? faqId : ''}
                    className='cursor-pointer'
                    header={
                        <>
                            <div className='flex flex-column w-full'>
                                <div className="flex align-items-center justify-content-between w-full faq-mobile-fontsize text-base">
                                    <div className='flex'>
                                        {/* <span className="sequence-number">{i + 1}.</span> */}
                                        <span className='ml-1'>{tab.faqQuestion}</span>
                                    </div>
                                    <div className='w-1 flex align-items-center justify-content-around'>
                                        <i className={`fa ${activeIndex === i ? 'fa-link' : ''}`} onClick={() => copyToClipboard(faqUrl, i)} />
                                        <i className={`fa fa-solid ${activeIndex === i ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    </div>
                                </div>

                                <hr />

                            </div>
                        </>
                    }

                    onClick={async () => {
                        await setAccordionClicked(true);
                        let newHash = `#${faqId.replace(/\s/g, '-')}`;
                        newHash = newHash.replace(/\?$/, '');
                        window.location.hash = newHash;
                    }}
                >
                    <div className="m-0 text-sm" style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: tab.faqAnswer }} />
                </AccordionTab>
            );
        });
    };

    const filteredFAQs = filterFAQs(apiFAQdata);

    const drphandlechange = (e) => {
        setSelectedLabel(e)
    }

    return (
        <div>
            <ReactHelmetTag link="https://www.sharepointempower.com/FAQ" />
            <Navbar />
            <div className='container-fluid'>
                <div className=' container faqheader fixed-top-faqs flex justify-content-between flex-wrap align-items-center py-2'>
                    <div className='flex align-items-center'>
                        <h1 style={{ fontSize: '26px', fontWeight: '600' }}>{window.innerWidth > 499 ? 'Frequently Asked Questions' : 'FAQs'}</h1>
                    </div>
                    <div>
                        {/* <CustomDropdown
                        value={selectedLabel}
                        options={labels.map(label => ({ label, value: label }))}
                        onChange={drphandlechange}
                        placeholder="Find Tag"
                        showClear
                    /> */}
                        <span className="p-input-icon-right w-auto ml-3">
                            <i className={`fa ${searchQuery ? 'fa-times-circle' : 'fa-search'}`} onClick={searchQuery ? clearSearch : null} style={{ cursor: searchQuery ? 'pointer' : 'default' }} />
                            <InputText placeholder="Search" value={searchQuery} onChange={handleSearch} className="p-inputtext-sm w-auto" />
                        </span>
                    </div>
                </div>
                <div className="container-fluid faqscroll">
                    {!project && (
                        <div className="container commonfaq">
                            {apiFAQdata.length > 0 ? (
                                <div>
                                    <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                        {createDynamicTabs(filteredFAQs)}
                                    </Accordion>
                                </div>
                            ) : (
                                <>
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                    <Skeleton width="100%" height="50px" className="mb-2" />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showCopyMessage && (
                <div className="copy-message">Copied URL</div>
            )}
            <Footer />
        </div>
    );
}

export default FAQ;