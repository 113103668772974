import React from 'react'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag';
import KnowledgeBaseHeading from '../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading';
import Allprojects from '../../CommonComponents/Userguide/Allprojects';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const IMSGuide = () => {
    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/guidelines/inventory-management-system"
                metaKeywords="Inventory management system guidelines, Inventory management system installation steps, Inventory management system help center, Sharepoint Inventory management system"
            />

            {/* <KnowledgeBaseHeading
                heading="Knowledge Base"
                modalTitle=" Inventory Management System"
                downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200006100"
                title="Download Inventory Management System" /> */}

            <section>
                <LazyLoadComponent>
                    <Allprojects project="Inventory Management System" />
                </LazyLoadComponent>
            </section >
        </div >
    )
}

export default IMSGuide