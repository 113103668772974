import React from 'react';
import { useContext, useEffect, useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import useLocalStorage from 'use-local-storage';
import { ApiDataContext } from '../../CommonComponents/Userguide/ApiDataContext';
import { Link } from 'react-router-dom/dist';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Allproducts.css';
import Footer from '../../Footer/Footer';
import ClientsAcrossTheGlobe from '../../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe';
import ProductOverview from '../ProductPage/ProductOverview';

export default function Allproducts() {
    const { apiproductdetaildata } = useContext(ApiDataContext);
    const [theme, setTheme] = useLocalStorage('theme', 'light');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        document.title = "Products";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (apiproductdetaildata && apiproductdetaildata.length > 0) {
            setLoading(false);
        }
    }, [apiproductdetaildata]);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    };

    function getLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../../assets/images/icons/LMP-b.png') : require('../../../assets/images/icons/LMP-w.png');
            case 'Asset Management System':
                return theme === 'light' ? require('../../../assets/images/icons/AMS-b.png') : require('../../../assets/images/icons/AMS-w.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../../assets/images/icons/audit-b.png') : require('../../../assets/images/icons/audit-w.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../../assets/images/icons/CMS-b.png') : require('../../../assets/images/icons/CMS-w.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../../assets/images/icons/spe-Intranet-b.png') : require('../../../assets/images/icons/spe-Intranet-w.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../../assets/images/icons/HD-b.png') : require('../../../assets/images/icons/HD-w.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../../assets/images/icons/leave-ms-b.png') : require('../../../assets/images/icons/leave-ms-w.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../../assets/images/icons/IMS-b.png') : require('../../../assets/images/icons/IMS-w.png');
            case 'Approval System':
                return theme === 'light' ? require('../../../assets/images/icons/AS-b.png') : require('../../../assets/images/icons/AS-w.png');

            default:
                return null;
        }
    }

    const getdetailProduct = (productName) => {
        switch (productName) {
            case 'Asset Management System':
                return '/asset-management-system';
            case 'Learning Management Portal':
                return '/learning-management-system';
            case 'Audit Inspection':
                return '/audit-inspection';
            case 'Construction Management System':
                return '/construction-management-system';
            case 'SPE Intranet':
                return '/spe-intranet';
            case 'HelpDesk System':
                return '/helpdesk-system';
            case 'Leave Management System':
                return '/leave-management-system';
            case 'Inventory Management System':
                return '/inventory-management-system';
            case 'Approval System':
                return '/approval-system';

            default:
                return null;
        }
    }

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                {/* <img className='backimgproducts' src={require("../../../assets/images/about_us_card_0.1.png")} alt="" /> */}
                <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline preload="auto" className='backimgproducts'>
                    <source src='https://www.sharepointempower.com/assets/videos/LandingPageVideo/sharepointEmpower-2.mp4' type='video/mp4' />
                </video>
            </div>
            <Navbar />
            <div className='allproductpage'>
                <div className='d-flex flex-row container justify-content-between mt-8'>
                    <div>
                        <h3 className=' allfirst-section-margin-top-for-navbar' style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions</h3>
                    </div>
                    <div className="light-mode-title productquotationbtn d-flex align-items-center pr-4">
                        <Link to="/faqs" className='pricingfaq'>
                            <i className="fa fa-circle-question mr-1" />
                            <span className='m-0'>FAQs</span>
                        </Link>
                    </div>
                </div>
                {window.innerWidth > 450 ?
                    <ProductOverview /> :
                    <div className='d-flex justify-content-center p-tablet'>
                        {loading ? (
                            <>
                                <div className='allproductpagebackground'>
                                    <Skeleton width="100%" height="100px" />
                                </div>
                                <div className='row container-fluid p-6'>
                                    {[1, 2, 3, 4, 5, 6, 7, 8].map(index => (
                                        <div
                                            className={`col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 `}
                                            key={index}
                                        >
                                            <div className='allproductpagebackground'>
                                                <Skeleton width="100%" height="200px" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className='row container-fluid p-5 pt-0'>
                                {apiproductdetaildata.map((product, index) => {

                                    let updatedTitle = product?.title

                                    if (product.title?.includes('Management') && product.title?.includes('System')) {
                                        updatedTitle = product.title.replace('System', '').trim();
                                    }
                                    return product.displayOrder === 0 ? null : (
                                        <div
                                            className={`col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 `}
                                            key={index}
                                        >
                                            <div className='allproductpagebackground '>
                                                <div className='allproductknow'>

                                                    <Link
                                                        to={getdetailProduct(product?.title)}
                                                        rel="noreferrer noopener"
                                                        tabIndex="-1"
                                                        aria-disabled="true"
                                                        className='text-xs ps-3 text-500'
                                                    >
                                                        <Card className='position-relative'>
                                                            <div className='flex allproducttitle'>
                                                                <img className="allproductIcon" src={getLogoForProduct(product.title)} alt="" />
                                                                <h4 className='allProductName text-left'>{updatedTitle}</h4>
                                                            </div>
                                                            <div>
                                                                <p className='text-md allproductdescription'>{product.description?.slice(0, 155)}
                                                                    <Link
                                                                        to={getdetailProduct(product?.title)}
                                                                        target="_blank"
                                                                        rel="noreferrer noopener"
                                                                        tabIndex="-1"
                                                                        aria-disabled="true"
                                                                        className='text-xs ps-1 readmore'
                                                                    >
                                                                        ...  Read More
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                            <div >
                                                                <div className='card-background-image' style={{ backgroundImage: `url(${getLogoForProduct(product.title)})` }}></div>
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                }
                <ClientsAcrossTheGlobe />
                <Footer />

            </div>

        </>
    );
}
