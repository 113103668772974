import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const InnerPageBanner = (props) => {
    return (
            <div className="banner-big-1-anim">
                <div className="container-fluid">
                    <div className={props.BannerImgClass}>
                        <div className="span-container-bottom">
                        </div>
                        <span className="span-content-bottom">{props.BannerContent}</span>
                    </div>
                </div>
            </div>
    )
}

export default InnerPageBanner