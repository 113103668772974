import Carousel from "react-multi-carousel";
import {Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};
const images = [
    require("../../assets/images/service_inner_page_3/react_logo_card.png"),
    require("../../assets/images/service_inner_page_3/microsoftsharepoint_logo_card.png"),
    require("../../assets/images/service_inner_page_3/js_logo_card.png"),
    require("../../assets/images/icons/angular.png"),
    require("../../assets/images/icons/css.png"),
    require("../../assets/images/icons/dotnet.png"),
    require("../../assets/images/icons/graphsql.png"),
    require("../../assets/images/icons/html.png") ,
    require("../../assets/images/icons/java.png"),
    require("../../assets/images/icons/nodejs.png"),
    require("../../assets/images/icons/typescript.png"),
];

const WebApplicationCarousel = ({ deviceType }) => {
    return (
        <Carousel
            ssr
            partialVisbile
            deviceType={deviceType}
            itemClass="image-item"
            responsive={responsive}
            autoPlay
        >
            {images.slice(0, 12).map(image => {
                return (
                    <div>
                        <div className="tech-icon-container-lm">
                            <div className="tech-QA-slider-lm d-flex-center-custom">
                                <Image
                                    draggable={true}
                                    src={image}
                                />
                            </div>

                        </div>

                    </div>
                );
            })}
        </Carousel>
    );
};

export default WebApplicationCarousel;
