import React, { useEffect, useState } from 'react'
import InnerPageBanner from '../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'

import CollaborativePartners from '../CommonComponents/CollaborativePartners/CollaborativePartners'
import ContentCards from '../CommonComponents/ContentCards/ContentCards'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import { CommitmentContentData } from './data'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ScrollAnimation from 'react-animate-on-scroll';
import ClientsAcrossTheGlobe from '../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'

const OurCommitment = () => {

    const [commitmentcarddata, setCommitmentContentData] = useState([])

    useEffect(() => {
        document.title = "Our commitment";
        setCommitmentContentData(CommitmentContentData)
        window.scrollTo(0, 0)
    })

    return (
        <div className='mt-7'>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/our-commitment"
                metaKeywords="Our commitment, our goals, our vision, our mission"
            />

            <Navbar />
            <PageHeadingContent heading="Our Commitment" subtitle="Our promise: Quality, Reliability, and Satisfaction" />

            <InnerPageBanner BannerImgClass="about-us-inner-2-banner-1-img" BannerContent="Our commitment is to provide our clients with the highest level of service and solutions that drive success and growth. We are committed to excellence in all that we do, and we strive to exceed our clients' expectations at every turn." />

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="light-mode-title">
                                    <h3 className="h4-sontentsubtitle-lm text-align-center h3-title-anim">
                                        Our Key Values
                                    </h3>
                                </div>
                        </div>
                    </div>

                    <div className="row service-inner-card-container">
                        {
                            commitmentcarddata.map(ele => {
                                return (
                                    <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} />

                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/* <!-- Software development section Start --> */}
            <section>
                <div className="container-fluid">
                    {/* <div className="container"> */}
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="light-mode-title">
                                    <h4 className="h4-sontentsubtitle-lm text-align-center h4-title-anim">
                                        Our Vision and Mission
                                    </h4>
                                </div>
                        </div>
                    </div>

                    {/* <!-- Row 1 starts --> */}
                        <div className="row service-inner-img-card-container">
                            {/* <!-- img container start  --> */}
                            <div className="vision-card-img-1-anim d-flex-center-custom col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="service-inner-img-card-body">
                                    <LazyLoadImage
                                        className="si2-img-card"
                                        src={require("../../assets/images/about_us_inner_page_2/img_card_1.png")}
                                        alt="card1"
                                        effect="blur"
                                        visibleByDefault={require("../../assets/images/about_us_inner_page_2/img_card_1.png")}
                                    />
                                    <div className="d-flex-center-custom overlay-card">
                                        <div className="si2-img-overlay"></div>
                                        <span className="si2-img-overlay-span">Our Vision</span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- img container end  --> */}

                            <div className="col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                {/* <!--list starts--> */}
                                <div className="row">
                                    <div className="light-mode-content">
                                        <p className="p-content-lm second-p-anim">
                                            We have started <b>SharepointEmpower Pvt. Ltd.</b> with an aspirational dream, the dream of developing and foreseeing the solutions that never were; we provide different applications and solutions that can integrate into one package to produce the excellent and most ample user friendly.
                                        </p>
                                    </div>

                                    <div
                                        className="about-us-feature-list about-us-feature-list-1-anim col col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                        <table className="table project-feature-ul-anim ">
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>To empower people around the world with our products and services create a positive impact on society. </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>To be the most trusted and respected brand in our market, known for our integrity and excellence in everything we do. </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>To continuously improve our products and services to exceed our customers' expectations and provide them with the best possible experience. </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>To be a responsible and ethical company, committed to environmental sustainability and social responsibility. </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--list end--> */}
                            </div>
                        </div>
                    {/* <!-- Row 1 ends --> */}


                    {/* <!-- Row 2 starts --> */}
                        <div className="row service-inner-img-card-container">
                            <div className="col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                {/* <!--list starts--> */}
                                <div className="row">
                                    <div className="light-mode-content">
                                        <p className="p-content-lm third-p-anim">
                                            To provide the highest quality and eminent professional software development & service worldwide. We set and follow standards to maintain the quality of our work and set the pace of the software, web development, and services. We believe that high-quality and cost-effective are complementary terms. We tend to work towards clients' satisfaction. We are not a low-cost center, but we can take pride in our effective costing and strive to deliver the best quality. Supreme Quality is our assurance, and we value your time.
                                        </p>
                                    </div>

                                    <div
                                        className="about-us-feature-list about-us-feature-list-2-anim col col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                        <table className="table project-feature-ul-anim ">
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>Our mission is to provide high-quality products and services that exceed our customers' expectations and create long-term value for all stakeholders. </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>We are committed to delivering innovative solutions that address the most pressing challenges of our time, from climate change to social inequality. </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>Our mission is to empower people and organizations with the tools and resources they need to achieve their goals and realize their full potential.</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="fa-solid fa-circle-check"></i></th>
                                                    <td>We are dedicated to being a responsible and ethical company, with a focus on sustainability, transparency, and accountability. </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--list end--> */}
                            </div>

                            {/* <!-- img container start  --> */}
                            <div className="vision-card-img-2-anim d-flex-center-custom col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="service-inner-img-card-body">
                                    <LazyLoadImage
                                        className="si2-img-card"
                                        src={require("../../assets/images/about_us_inner_page_2/img_card_2.png")}
                                        alt="card2"
                                        effect="blur"
                                        visibleByDefault={require("../../assets/images/about_us_inner_page_2/img_card_2.png")}
                                    />
                                    <div className="d-flex-center-custom overlay-card">
                                        <div className="si2-img-overlay"></div>
                                        <span className="si2-img-overlay-span">Our Mission</span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- img container end  --> */}
                        </div>
                    {/* <!-- Row 2 ends --> */}

                    {/* </div> */}
                </div>
            </section>
            {/* <!-- Software development section End --> */}
             {/* <CollaborativePartners /> */}
            <ClientsAcrossTheGlobe />
            
            <Footer />
        </div>
    )
}

export default OurCommitment