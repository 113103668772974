import React, { useContext, useEffect} from 'react'

import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import InnerPageBanner from '../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext'
import ScrollAnimation from 'react-animate-on-scroll'

const MicrosoftBased = () => {
  const { apiproductdetaildata } = useContext(ApiDataContext);

  useEffect(() => {
    document.title = "Microsoft 365 apps and services";
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/microsoft-based"
        metaTitle="Microsoft 365 apps and services"
        metaDescription="Unlock the full potential of productivity with Microsoft 365 apps and services. Contact us today for microsoft 365 app development and managed services."
        metaKeywords="microsoft 365 app development, microsoft 365 apps, microsoft 365 managed services"
      />

      <Navbar />
      <PageHeadingContent heading="Microsoft 365 Based Products" subtitle="Experience the benefits of cloud-based technology with our Microsoft 365-based applications"
        content="Microsoft 365 is a powerful suite of cloud-based tools that can help businesses of all sizes streamline
      operations, collaborate more effectively, and enhance productivity. At SharepointEmpower, we
      specialize in developing Microsoft 365-based applications that can help businesses leverage the power of
      this platform to achieve their goals." />

      <InnerPageBanner BannerImgClass="microsoft-based-1-img" BannerContent="With Microsoft 365-based applications, you can take advantage of the latest cloud-based technologies to improve collaboration, enhance security, and streamline operations. Our applications are designed to be intuitive and user-friendly, so your team can start using them right away with minimal training." />

      <div className="container-fluid">
        <div className="container">
          <div className="row service-inner-card-container">
            {
              apiproductdetaildata?.map(ele => {
                return (
                  <div className="service-inner-card-1-anim col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="service-inner-card-body d-flex-center-custom">
                        <img className="card-line-vector" src={require("../../assets/images/service_inner_page/line_design_for_card.png")}
                          alt="line_design_for_card" />
                        <img className="card-icon-vector" src={require("../../assets/images/service_inner_page/card-icon-vector.png")}
                          alt="card-icon-vector" />
                        <span className="service-inner-card-title">{ele?.title}</span>
                        <p className="service-inner-card-content">
                          {ele?.description?.slice(0, 175)}  ... Read More
                        </p>
                        <Link className="service-inner-card-button" to={ele?.routePath}>View Product</Link>
                      </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      {/* <CollaborativePartners /> */}
      
      <Footer />
    </div>
  )
}

export default MicrosoftBased