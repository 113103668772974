import React, { useContext } from 'react';
import { Image, Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import { format } from 'date-fns';

// Register Font
Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  body: {
    // q
    fontSize: 10,
    fontFamily: "Roboto"
  },
  logo: {
    width: 150,
    height: 33,
  },
  signature: {
    width: 150,
    height: 75,
  },
  microsoftimg: {
    width: 130,
    height: 60,
  },
  logoContainer: {
    justifyContent: 'left',
    width: '40%',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addresscontainer: {
    width: '50%',
  },
  Quotation: {
    textAlign: 'right',
    fontSize: 28,
    marginBottom: 5,
    fontWeight: 900,
  },
  address: {
    textAlign: 'right',
    fontSize: 10,
  },
  headernote: {
    textAlign: 'center',
    fontSize: 12,
    margin: 15,
    fontWeight: 500,
  },
  empname: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
    backgroundColor: '#F9F9FA',
    padding: 20,
    fontSize: 9,
  },
  accountdetails: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 55,
    width: '100vw',
    justifyContent: 'space-between',
    padding: 20,
    fontSize: 10,
  },
  leftSection: {
    textAlign: 'left',
  },
  rightSection: {
    textAlign: 'right',
    fontSize: 11,
  },
  ACrightSection: {
    marginTop: 50,
    fontSize: 10,
  },
  leftText: {
    fontWeight: 700,
    fontSize: 11,
  },
  ACleftText: {
    fontWeight: 700,
    padding: 2,
    fontSize: 11,
  },
  leftTextValue: {
    fontWeight: 400,
    fontSize: 11,
  },
  ACleftTextValue: {
    fontWeight: 400,
    padding: 2,
    fontSize: 11,
  },
  quotationTableBody: {
    marginTop: 10,
    border: '1px solid #D9D9D9',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 30,
  },
  productTableBody: {
    borderLeft: '1px solid #D9D9D9',
    borderRight: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // height: 30,
  },
  noBottomBorder: {
    borderBottom: '1px solid #D9D9D9',
    // borderBottomWidth: 0,
  },
  amountTable: {
    marginTop: '1%',
  },
  totalamountTable: {
    flexDirection: 'row',
    height: 30,
    justifyContent: 'space-between',
  },
  blankrow: {
    paddingTop: 5,
    fontSize: 12,
    fontWeight: 700,
    width: "60%",
  },
  grandtotal: {
    paddingTop: 5,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 400,
    width: "20%",
    backgroundColor: '#F9F9FA',
    borderTop: '1px solid #D9D9D9',
  },
  grandtotalvalue: {
    paddingTop: 5,
    paddingRight: 5,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 500,
    width: "20%",
    backgroundColor: '#F9F9FA',
    borderTop: '1px solid #D9D9D9',
  },
  ownedgrandtotal: {
    paddingTop: 5,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 400,
    width: "20%",
    backgroundColor: '#F9F9FA',
    borderBottom: '1px solid #D9D9D9',
  },
  ownedgrandtotalvalue: {
    paddingTop: 5,
    paddingRight: 5,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 500,
    width: "20%",
    backgroundColor: '#F9F9FA',
    borderBottom: '1px solid #D9D9D9',
  },
  descriptionofwork: {
    paddingTop: 5,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700,
    width: "60%",
  },
  unitprice: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700,
    paddingTop: 5,
    width: '17%',
    borderRight: '1px solid #D9D9D9',
    borderLeft: '1px solid #D9D9D9',
  },
  subtotal: {
    paddingTop: 5,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700,
    width: '17%',
    // borderRight: '1px solid black'
  },
  productname: {
    fontWeight: 500,
    paddingLeft: 10,
    textAlign: 'left',
    fontSize: 10,
  },
  productCustomization: {
    fontWeight: 500,
    textAlign: 'left',
    // marginTop: 5
  },
  productprice: {
    borderRight: '1px solid #D9D9D9',
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 400,
    paddingTop: 5,
    paddingRight: 4,
    width: '17%',
    borderLeft: '1px solid #D9D9D9',
  },
  subPrice: {
    paddingTop: 5,
    paddingRight: 4,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 400,
    width: '17%',
  },
  accountheader: {
    fontSize: 14,
    fontWeight: 500,
    marginVertical: 10,
  },
  emailSection: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 0,
    width: '100vw',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    fontSize: 10,
  },
  email: {
    fontSize: 10,
    fontWeight: 500,
    flexDirection: 'row',
    width: '33%',
    textAlign: 'left',
  },
  signatureContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  websiteContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  websiteimg: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  websiteText: {
    fontSize: 10,
  },
  head: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
  },
  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.8,
  },
  productName: {
    display: 'flex',
    flexDirection: 'row',
    width: "60%",
    paddingTop: 5,
    paddingRight: 5,
  },
  Companylocation: {
    marginLeft: 'auto',
    width: 250
  }
});

const QuotationPDF = ({ formData }) => {

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let financialYearStartYear;
  if (currentMonth >= 3) {
    financialYearStartYear = currentYear;
  } else {
    financialYearStartYear = currentYear + 1;
  }

  const previousYear = financialYearStartYear + 1;
  const currentYearShort = financialYearStartYear;
  const previousYearShort = previousYear.toString().slice(-2);
  const quotationNo = `${currentYearShort}-${previousYearShort}/GST/${formData.randomNum}`;

  let totalAnnualPrice = 0;

  const formatDate = (date) => {
    // return format(new Date(date), "yyyy MMMM',' dd");
    return format(new Date(date), "dd/MM/yyyy");
  };

  return (
    <Document>
      <Page style={styles.body} size="A4">
        <View style={styles.head}>
          <View style={styles.headerContainer}>
            <View style={styles.logoContainer}>
              <Image src={require("../../assets/images/SPE NEW Logo Black.png")} style={styles.logo} />
            </View>
            <View style={styles.addresscontainer}>
              <View style={styles.Quotation}>
                <Text>QUOTATION</Text>
              </View>
              <View style={styles.address}>
                <Text>Sharepoint Empower Private Limited</Text>
                <Text>603-604, RK Prime - 2, 150 Feet Ring Rd,</Text>
                <Text>Rajkot - 360004, Gujarat, India</Text>
              </View>
            </View>
          </View>
          <View style={styles.headernote}>
            <Text>SUPPLY MEANT FOR EXPORT UNDER LUT WITHOUT PAYMENT OF INTEGRATED TAX</Text>
          </View>
        </View>
        <View style={styles.empname}>
          <View style={styles.leftSection}>
            <Text style={styles.leftText}>Date: <Text style={styles.leftTextValue}>{formatDate(new Date())}</Text></Text>
            <Text style={styles.leftText}>QUOTATION No: <Text style={styles.leftTextValue}>{quotationNo}</Text></Text>
            <Text style={styles.leftText}>GSTIN: <Text style={styles.leftTextValue}>24ABFCS6335K1Z4</Text></Text>
            <Text style={styles.leftText}>PAN: <Text style={styles.leftTextValue}>ABFCS6335K</Text></Text>
            <Text style={styles.leftText}>TIN: <Text style={styles.leftTextValue}>RKTS17324G</Text></Text>
          </View>
          <View style={styles.rightSection}>
            <Text style={{ marginLeft: 'auto' }}>To,</Text>
            <Text style={{ marginLeft: 'auto' }}>{formData.userName}</Text>
            <Text style={{ marginLeft: 'auto' }}>{formData.userEmail}</Text>
            <Text style={{ marginLeft: 'auto' }}>{formData.userCompany}</Text>
            <Text style={styles.Companylocation}>{formData.userLocation}</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.quotationTableBody}>
            <Text style={styles.descriptionofwork}>Description of Work</Text>
            <Text style={styles.unitprice}>Unit Price</Text>
            <Text style={styles.subtotal}>Sub Total</Text>
          </View>

          {formData.userService?.map((item, index, array) => {
            const price = item.price;
            const annualPrice = price * 12;
            totalAnnualPrice += annualPrice;

            const isLastItem = index === array.length - 1;

            return (
              <View style={[styles.productTableBody, !isLastItem && styles.noBottomBorder]} key={index}>
                <View style={styles.productName}>
                  <Text style={styles.productname}>{`${index + 1}. `}</Text>
                  <Text style={styles.productCustomization}>{`M365 - ${item.title}`} *{item.planDescription}</Text>
                </View>
                {/* <Text style={styles.productname}>{`${index + 1}. M365 - ${item.title}`} *{item.planDescription}</Text> */}
                <Text style={styles.productprice}>{annualPrice} USD</Text>
                <Text style={styles.subPrice}>{annualPrice} USD</Text>
              </View>
            );
          })}

          <View style={styles.amountTable}>
            <View style={styles.totalamountTable}>
              <Text style={styles.blankrow}></Text>
              <Text style={styles.grandtotal}>Grand Total:</Text>
              <Text style={styles.grandtotalvalue}>{totalAnnualPrice.toLocaleString()} USD</Text>
            </View>
            <View style={styles.totalamountTable}>
              <Text style={styles.blankrow}></Text>
              <Text style={styles.ownedgrandtotal}>Balance Owned:</Text>
              <Text style={styles.ownedgrandtotalvalue}>{totalAnnualPrice.toLocaleString()} USD</Text>
            </View>
          </View>
        </View>
        <View style={styles.accountdetails} fixed>
          <View style={styles.leftSection}>
            <Text style={styles.ACleftText}>*Note : This Quotation is valid for 7 Days from the date of issuance.</Text>
            <Text style={styles.accountheader}>Accout Details</Text>
            <Text style={styles.ACleftText}>Bank Name: <Text style={styles.ACleftTextValue}>HDFC Bank</Text></Text>
            <Text style={styles.ACleftText}>Account Name: <Text style={styles.ACleftTextValue}>SharePointEmpower Private Limited</Text></Text>
            <Text style={styles.ACleftText}>Account Number: <Text style={styles.ACleftTextValue}>50200057382581</Text></Text>
            <Text style={styles.ACleftText}>Swift Code: <Text style={styles.ACleftTextValue}>HDFCINBB</Text></Text>
            <Text style={styles.ACleftText}>IFSC Code: <Text style={styles.ACleftTextValue}>HDFC0000379</Text></Text>
            <Text style={styles.ACleftText}>Bank Address: <Text style={styles.ACleftTextValue}>HDFC Bank LTD, Kalawad Road, Rajkot – 360005, Gujarat, India.</Text></Text>
          </View>
          <View style={styles.ACrightSection}>
            <Image src={require("../../assets/images/signature.png")} style={styles.signature} />
            <Text style={{ marginLeft: 20 }}>Authorized Signatory</Text>
          </View>
        </View>
        <View style={styles.emailSection} fixed>
          <View style={styles.container}>
            <View style={styles.websiteContainer}>
              <Image
                source={require("../../assets/images/Mail.png")}
                style={styles.websiteimg}
              />
              <Text style={styles.websiteText}>support@sharepointempower.com</Text>
            </View>
          </View>
          <View style={styles.signatureContainer}>
            <Image src={require("../../assets/images/service_inner_page/4.png")} style={styles.microsoftimg} />
          </View>
          <View style={styles.container}>
            <View style={styles.websiteContainer}>
              <Image
                source={require("../../assets/images/Website.png")}
                style={styles.websiteimg}
              />
              <Text style={styles.websiteText}>www.sharepointempower.com</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuotationPDF;
