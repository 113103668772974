import React, {useEffect } from 'react';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import useLocalStorage from 'use-local-storage';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import CustomGlobe from '../../CustomGlobe';
import './Globalmap.css'

function Globalmap() {
    const [theme, setTheme] = useLocalStorage('theme', 'light');

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        document.title = "Global Client Map";
    }, []);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark');
    };

    return (
        <>
            <ReactHelmetTag link="https://www.sharepointempower.com/product-pricing-list" />
            <Navbar />
            <CustomGlobe />
            
            <Footer />
        </>
    );
}

export default Globalmap;
