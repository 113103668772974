import React from "react";
import Footer from "../../Footer/Footer";
import ReactHelmetTag from "../../CommonComponents/ReactHelmet/ReactHelmetTag";
import KnowledgeBaseHeading from "../../CommonComponents/KnowledgeBaseHeading/KnowledgeBaseHeading";
import Allprojects from "../../CommonComponents/Userguide/Allprojects";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function LMPGuide() {
  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/guidelines/learning-management-system"
        metaKeywords="Learning management system guidelines, Learning management system installation steps, Learning management system help center, Sharepoint Learning management system, LMS, Learning management portal steps"
      />

      {/* <KnowledgeBaseHeading
        heading="Knowledge Base"
        modalTitle=" Learning Management System"
        downloadLink="https://appsource.microsoft.com/en-us/product/office/WA200005182?exp=kyyw"
        title="Download Learning Management Portal"
      /> */}
      <section>
        <LazyLoadComponent>
          <Allprojects project="Learning Management Portal" />
        </LazyLoadComponent>
      </section>
    </div>
  );
}

export default LMPGuide;
