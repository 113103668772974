export const CollabPartners = [
    {
        partnerIcon: "microsoft-icon-logo"
    },
    {
        partnerIcon: "Microsoft-solution-partner-white",
        PDFlink:"https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf"
    },
    {
        partnerIcon: "azure-icon-logo"
    }
]