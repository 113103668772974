import React, { useContext, useEffect } from 'react'

import CollaborativePartners from '../CommonComponents/CollaborativePartners/CollaborativePartners'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import { Link } from 'react-router-dom'
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext'

const JobOpenings = () => {

    const { apiJobOpeningdata } = useContext(ApiDataContext);
    console.log("apiJobOpeningdata", apiJobOpeningdata)

    useEffect(() => {
        document.title = "Job openings";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='mt-7'>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/job-openings"
                metaKeywords="job openings at sharepointempower, job opportunities, job opening"
            />
            <PageHeadingContent heading="Job Openings" content="Join our team and become part of a community that values collaboration, creativity, and innovation. We're committed to helping you grow in your career and achieve your full potential." />

            <section className="job job-row-1-anim" id="software-engineering-jobs">
                <div className="container-fluid">
                    <div className="container">
                        <div className="before-row-margin-lm"></div>
                        {apiJobOpeningdata.length > 0 ? (
                            <>
                                <p><b>- To apply for the job position, simply click the 'Apply' button and submit your resume to us. We'll be in touch with you shortly.</b></p>
                                {apiJobOpeningdata.map((job, index) => (
                                    <div className='row' key={index}>
                                        <div className='col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                                            <h4><b>{job.title}</b></h4>
                                            <p>{job.jobDescription}</p>
                                            <p className='font-semibold'>Number of Positions : <span>{job.jobPosition}</span></p>
                                        </div>
                                        <div className='col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-end productKnowbutton'>
                                            <a className='m-3 mx-0 text-sm p-2 col-4 col-md-6 col-lg-3' href="mailto:career@sharepointempower.com">
                                                Apply<i className="fa-solid fa-up-right-from-square px-1"></i>
                                            </a>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>- For any inquiries regarding open positions at our company, please contact us at <a className='underline text-blue-500' href="mailto:career@sharepointempower.com">career@sharepointempower.com</a></p>
                        )}

                    </div>
                </div>
            </section>
        </div>
    )
}

export default JobOpenings